import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import {
  ContainerMain,
  CardHeader,
  Title, FormGroupCustom, FormLabelCustom, CardFooterCustom, CardIcon,
  ButtonSave, ButtonCancel, DocAttached, TextDocAttached, AttachedUploadIcon, RemoveAttached
} from './styles';
import { MdDelete, MdUploadFile, } from 'react-icons/md';
import ModalCodeValidate from './Components/ModalCodeValidate';
import { useStateValue } from '../../providers/StateProvider';
import { actionTypes } from '../../store/reducer';
import CancelModal from '../../Components/CancelModal';
import MyEditor from '../../Components/MyEditor';
import { attachDocumentRequests } from '../../services/requests';
import { CircularProgress } from '@material-ui/core';

const AttachDocument = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileObject[]>([]);
  const [showMessageError, setShowMessageError] = useState<any>();
  const { identificador, id } = useParams<{ identificador: string, id: string }>();
  const [formState, setFormState] = useState<any>({
    assunto: '',
    descricao: '',
  });

  const removeFile = (file: FileObject) => {

    const fileFilter = files.filter(
      (fileFiltering) => fileFiltering.data !== file.data
    );
    setFiles(fileFilter);

    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Excluído',
        message: 'Documento excluído com sucesso.',
      },
    });
  };

  const [showModalCode, setShowModalCode] = useState(false);

  const [, setMessageErrorUpload] = useState<
    undefined | string
  >(undefined);
  const [, setIsErrorInUpload] = useState(false);
  const handleUploadsAlert = (variant: string) => {
    if (variant === 'error') {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  };

  const handleErrors = () => {
    const errors = {
      assunto: !formState.assunto,
      descricao: !formState.descricao,
      arquivo: !files.length,
    };
    console.log(errors);

    setShowMessageError(errors);

    return Object.values(errors).some((item) => item);
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!identificador || !id) {
      return;
    }
    if (handleErrors()) {
      return;
    }
    setLoading(true)
    try {
      const promises = files?.map((fileObj) => {
        let form = new FormData();
        form.append('solicitacao', id);
        form.append('assunto', formState.assunto);
        form.append('descricao', formState.descricao);
        form.append('arquivo', fileObj.file);

        return attachDocumentRequests(identificador, form);
      });

      const results = await Promise.all(promises);

      if (results.some(result => result.data)) {
        history.goBack();
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success',
            title: 'Sucesso',
            message: 'Documento(s) anexado(s) com sucesso!',
          },
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Erro ao salvar',
          message: 'Erro ao tentar adicionar o documento! Tente novamente',
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleCancel = () => {
    setShowModalCancel(true);
  }

  const handleCloseCancel = () => {
    history.goBack();
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Cancelado',
        message: 'Documento não foi criado.',
      },
    });
  }
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  return (
    <>
      <ModalCodeValidate show={showModalCode} setShow={setShowModalCode} />
      <ContainerMain>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>
              <div>
                <CardIcon>
                  <MdUploadFile size={20} />
                </CardIcon>
              </div>
              <Title>
                Anexar Documento
              </Title>
            </CardHeader>
            <Card.Body>
              <FormGroupCustom className="mb-3" controlId="">
                <FormLabelCustom required>Assunto</FormLabelCustom>
                <Form.Control
                  required
                  type="text"
                  placeholder="Informe o assunto"
                  value={formState.assunto}
                  onChange={(e) => setFormState({ ...formState, assunto: e.target.value })}
                >
                </Form.Control>

                <Form.Text className={showMessageError?.assunto ? "text-danger" : "text-muted"} >
                  {showMessageError?.assunto ? "Este campo não pode ser vazio." : "Informe"}

                </Form.Text>
              </FormGroupCustom>
              <FormGroupCustom>
                <FormLabelCustom required>Resumo</FormLabelCustom>
                <MyEditor
                  value={formState.descricao}
                  setValue={(value) => setFormState({ ...formState, descricao: value })}
                  addConf={{
                    height: 300
                  }}
                />
                {console.log(files)}
                <Form.Text className={showMessageError?.descricao ? "text-danger" : "text-muted"}>
                  {showMessageError?.descricao ? "Este campo não pode ser vazio." : "Descreva"}
                </Form.Text>
              </FormGroupCustom>
              <FormGroupCustom>
                <FormLabelCustom required>Documento</FormLabelCustom>
                {files.length === 0 && (<DropzoneAreaBase
                  onAdd={(filesUpload) => {
                    setFiles(filesUpload);
                    setMessageErrorUpload(undefined);
                  }}
                  fileObjects={files}
                  acceptedFiles={["image/jpeg", "image/png", ".pdf"]}
                  showPreviewsInDropzone={false}
                  dropzoneText="Escolha um arquivo ou arraste-o aqui"
                  showFileNames={false}
                  showFileNamesInPreview={false}
                  showAlerts={false}
                  filesLimit={30}
                  maxFileSize={31457280}
                  onAlert={(_, variant) => handleUploadsAlert(variant)}
                />)}
                {showMessageError?.arquivo && (
                  <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>
                    Este campo não pode ser vazio.
                  </p>
                )}
                {files && files.map((file, index) => (
                  <DocAttached key={index}>
                    <TextDocAttached>
                      <AttachedUploadIcon />
                      {`${file?.file?.name} (${formatBytes(file.file?.size)})`}

                    </TextDocAttached>
                    <RemoveAttached onClick={(): void => removeFile(file)}><MdDelete /></RemoveAttached>
                  </DocAttached>))}
              </FormGroupCustom>
            </Card.Body>
            <CardFooterCustom>
              <ButtonCancel
                onClick={handleCancel}>
                Cancelar
              </ButtonCancel>
              <ButtonSave
                type="submit"
              >
                {loading ? <CircularProgress size={25} color='inherit' /> : 'Salvar'}

              </ButtonSave>
            </CardFooterCustom>
          </Card>
        </form>
      </ContainerMain>

      <CancelModal
        show={showModalCancel}
        handleShow={setShowModalCancel}
        title="Cancelar documento"
        description='Tem certeza que deseja cancelar este documento? Essa ação não pode ser desfeita.'
        handleActionOk={handleCloseCancel}
      />
    </>
  );
}

export default AttachDocument;