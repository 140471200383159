import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Col, Dropdown, Form } from "react-bootstrap";
import { MdRemoveCircle, MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton,
} from "../Information/components/ManageForm/styles";
import { actionTypes } from "../../store/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { getCredentialsList } from "../../services/credenciais";
import SelectWithSearch from "../../Components/SelectWithSearch";
import { createWorkflowByFlowId, getWebhooksRequest, getWorkflowByFlowId, updateWorkflowByFlowId } from "../../services/flow";
import { getSelectorsByFlow } from "../../services/field";

const NewWorkflow = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const { flow_slug } = useParams<{ flow_slug: string }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [credencial, setCredencial] = useState<any>(null);
  const [credentialsList, setCredentialsList] = useState<any>();
  const [webHook, setWebHook] = useState<any>();
  const [webHookList, setWebHookList] = useState<any>([]);
  const [selectors, setSelectors] = useState([]);

  const [mapa, setMapa] = useState<any>([
    {
      chave: "",
      valor: "",
    },
  ]);
  const [workflow, setWorkflow] = useState<any>({
    descricao: "",
    titulo: "",
    url: "",
    credencial: null,
    flow: flow_slug,
    mapa: {},
  });

  const setCredenciaisValueFormatted = (values) => {
    if (values) {
      let credencialOptions = values.map((value) => ({
        label: value.titulo,
        value: value.id,
      }));
      setCredentialsList(credencialOptions);
    }
  };

  const setWebHooksValueFormatted = (values) => {
    if (values) {
      let webHookOptions = values.map((value) => ({
        label: `${value?.workflow?.name || ''} - ${value.webhookPath}`,
        value: `${value.webhookPath}`,
      }));
      setWebHookList(webHookOptions);
    }
  };

  const getCredentials = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getCredentialsList();
      if (data) {
        setCredenciaisValueFormatted(data?.results || data || []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);
  const getUrlsWebhooks = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getWebhooksRequest();
      if (data) {
        setWebHooksValueFormatted(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getWorkflow = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getWorkflowByFlowId(flow_slug);
      if (data?.length) {
        const workflowData = data[0];
        setWorkflow({
          id: workflowData.id,
          titulo: workflowData.titulo,
          descricao: workflowData.descricao,
          url: workflowData.url,
          credencial: workflowData.credencial,
          flow: workflowData.flow,
        });
        const mapaFormatted = Object.entries(workflowData.mapa).map(([chave, valor]) => ({
          chave,
          valor,
        }));
        setMapa(mapaFormatted);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [flow_slug]);

  const handleSelectCredencial = (value: { label: string; value: string }) => {
    if (value) {
      setCredencial(value);
      setWorkflow({ ...workflow, credencial: value.value });
    }
  };

  const handleSelectWebHook = (value: { label: string; value: string }) => {
    if (value) {
      setWebHook(value);
      setWorkflow({ ...workflow, url: value.value });
    }
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      if (workflow.id) {
        await updateWorkflow(workflow);
      } else {
        await createWorkflow(workflow);
      }

      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "success-alt",
          title: "Sucesso",
          message: "Workflow salvo com sucesso!",
        },
      });

      history.push(`/informacoes/${flow_slug}`);
    } catch (err) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Erro",
          message: "Erro ao salvar workflow! Tente novamente",
        },
      });
      console.error(err);
    }
  };

  const getAllSelectors = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getSelectorsByFlow(flow_slug);
      setSelectors(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [flow_slug]);

  const updateWorkflow = async (workflowData) => {
    const mapaFormatted = Object.fromEntries(mapa.map((item) => [item.chave, item.valor]));
    await updateWorkflowByFlowId(
      {
        id: workflowData.id,
        flow_slug,
      },
      { ...workflowData, mapa: mapaFormatted }
    );
  };

  const createWorkflow = async (workflowData) => {

    const mapaFormatted = Object.fromEntries(mapa.map((item) => [item.chave, item.valor]));
    await createWorkflowByFlowId(flow_slug, {
      ...workflowData,
      mapa: mapaFormatted,
    });
  };

  useEffect(() => {
    const initialize = async () => {
      if (flow_slug) {
        await getCredentials();
        await getUrlsWebhooks();
        await getAllSelectors();
        await getWorkflow();
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow_slug]);

  useEffect(() => {

    const credentialId = credentialsList?.find((item) => item.value === workflow.credencial);
    if (credentialId) {
      setCredencial(credentialId);
    }
  }, [credentialsList, workflow]);


  useEffect(() => {

    const urlId = webHookList?.find((item) => item.value === workflow.url);
    if (urlId) {
      setWebHook(urlId);
    }

  }, [webHookList, workflow]);

  return (
    <ContainerMain>
      <form onSubmit={handleSave}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>{"Gerenciar Workflow"}</Title>
          </CardHeader>

          <Card style={{ border: 0, padding: "40px 40px 0px 40px" }}>
            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Título</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={workflow.titulo}
                    onChange={(event) => setWorkflow({ ...workflow, titulo: event.target.value })}
                  />
                  <Form.Text className="text-muted">Informe o título.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Descrição</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={workflow.descricao}
                    onChange={(event) => setWorkflow({ ...workflow, descricao: event.target.value })}
                  />
                  <Form.Text className="text-muted">Informe o Descrição.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Webhook Workflow</FormLabel>
                  <SelectWithSearch
                    name="url"
                    options={webHookList}
                    isDisabled={!webHookList || loading}
                    value={webHook}
                    onChange={handleSelectWebHook}
                    placeholder="Selecione ou digite nome do webhook"
                  />
                  {/* <Form.Control
                    disabled={loading}
                    type="url"
                    required
                    value={workflow.url}
                    onChange={(event) => setWorkflow({ ...workflow, url: event.target.value })}
                  /> */}
                  <Form.Text className="text-muted">Informe a url do workflow.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Credencial</FormLabel>
                  <SelectWithSearch
                    name="credencial"
                    options={credentialsList}
                    isDisabled={!credentialsList || loading}
                    value={credencial}
                    onChange={handleSelectCredencial}
                    placeholder="Selecione ou digite o nome da credencial"
                  />
                  <Form.Text className="text-muted">Selecione a Credencial.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Mapa</FormLabel>
                <Form.Text className="text-muted">
                  O mapa define os campos que serão utilizados na integração
                </Form.Text>
                {mapa.map((map, index) => (
                  <CustomRow key={index.toString()}>
                    <Col md={4}>
                      <FormLabel>Chave</FormLabel>
                      <Form.Control
                        disabled={loading}

                        required
                        value={map.chave}
                        onChange={(event) => {
                          const newMapa = [...mapa];
                          newMapa[index].chave = event.target.value;
                          setMapa(newMapa);

                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomRow>
                        <Col md={8}>
                          <FormLabel>Valor</FormLabel>
                          <Form.Control disabled={loading} required value={map.valor}
                            onChange={(event) => {
                              const newMapa = [...mapa];
                              newMapa[index].valor = event.target.value;
                              setMapa(newMapa);
                            }}
                          />
                        </Col>
                        <Col md={4} >
                          <Dropdown style={{
                            marginTop: 30,
                          }}>
                            <Dropdown.Toggle id="dropdown-basic" disabled={loading}>
                              Campos formulário
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {selectors.map((item) => (
                                <Fragment key={item?.slug}>
                                  <Dropdown.Item disabled>Formulário: {item?.titulo || ""}</Dropdown.Item>
                                  {item?.campos?.map((campo) => (
                                    <Dropdown.Item onClick={() => {
                                      const newMapa = [...mapa];
                                      newMapa[index].valor = "${" + `${campo.id} - ${campo.titulo}` + "}";
                                      setMapa(newMapa);
                                    }} key={campo?.id}>
                                      {campo?.titulo || ""}
                                    </Dropdown.Item>
                                  ))}
                                </Fragment>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </CustomRow>
                    </Col>
                    <Col >
                      {mapa.length > 1 && (<Button
                        variant="danger"
                        onClick={() => {
                          setMapa(mapa.filter((item, i) => i !== index));
                        }}
                        style={{
                          marginTop: 30,
                        }}
                      >
                        <MdRemoveCircle /> Remover
                      </Button>)}

                    </Col>
                  </CustomRow>
                ))}
                <CustomRow>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setMapa([
                          ...mapa,
                          {
                            chave: "",
                            valor: "",
                          },
                        ]);
                      }}
                      disabled={loading}
                    >
                      Adicionar campo ao mapa
                    </Button>
                  </Col>
                </CustomRow>
              </Form.Group>
            </CustomRow>
          </Card>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.push(`/informacoes/${flow_slug.trim()}`)}
            >
              <span style={{ marginLeft: 8 }}>Cancelar</span>
            </ActionButton>
            <ActionButton style={{ backgroundColor: "#27AB6E" }} type="submit">
              <span style={{ marginLeft: 8 }}>Salvar</span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form >
    </ContainerMain >
  );
};

export default NewWorkflow;
