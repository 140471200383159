import styled from "styled-components";

interface PropsTitle {
  type: string;
}

interface CircleProps {
  type: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const CircleBox = styled.span<CircleProps>`
  background-color: ${({ theme, type }) =>
    {
      switch (type) {
        case "Solicitado":
          return theme.colors.secundary;
        case "Aguardando":
          return theme.colors.secundary;
        case "Em atendimento":
          return theme.colors.primary;
        case "Finalizado":
          return theme.colors.success;
        case "Cancelado":
          return theme.colors.error;
        default:
          return theme.colors.secundary;
      }
    }
  };
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const Title = styled.span<PropsTitle>`
  color: ${({ theme, type }) =>
    {
      switch (type) {
        case "Solicitado":
          return theme.colors.secundary;
        case "Aguardando":
          return theme.colors.secundary;
        case "Em atendimento":
          return theme.colors.primary;
        case "Finalizado":
          return theme.colors.success;
        case "Cancelado":
          return theme.colors.error;
        default:
          return theme.colors.secundary;
      }
    }
  };
`;