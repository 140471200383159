import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Loading from '../../../../Components/Loading';

import {
  ContainerMain,
  CardHeader,
  Title,
  CardFooterCustom,
  CardIcon,
  BoxLoading,
  ButtonReturn
} from './styles';
import { MdDescription, } from 'react-icons/md';
import MyEditor from '../../../../Components/MyEditor';
import { getVisualizationResponse } from '../../../../services/documents';
import dateFormat from '../../../../utils/dateFormat';
import { cpfMask } from '../../../../utils/cpfFormat';

export interface DocumentProps {
  assunto: string;
  cabecalho: string;
  conteudo: string;
  created_at: string;
  descricao: string;
  hash?: string;
  id: string;
  solicitacao: number;
  updated_at: string;
  user: any;
}

const VisualizationDocumentResponse = () => {
  const history = useHistory();
  const { solicitacao_id, id_document } = useParams<{ solicitacao_id: string; orgao_id: string; id_document?: string }>();
  const [assunto, setAssunto] = useState<string>('');
  const [, setDescricao] = useState<string>('');
  const [conteudo, setConteudo] = useState<string>('');
  const [selectCabecalho, setSelectCabecalho] = useState<any>();
  const [loading] = useState<boolean>(false);
  const [documentData, setDocumentData] = useState<any>(null);




  useEffect(() => {
    async function getDocument() {
      try {
        const { data } = await getVisualizationResponse(id_document, solicitacao_id);
        setDocumentData(data);
        // setHeaders(data.results);
        setDescricao(data.descricao);
        setAssunto(data.assunto);
        setSelectCabecalho(data.cabecalho)
        setConteudo(data.conteudo)
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    }
    getDocument()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQRCodeImageURL = (value) => {
    return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(value)}&size=80x80`;
  }

  const addInfoSign = () => {
    const qrCodeValue = `${(window as any)._env_.REACT_APP_URL_CONSULTAR_DOC}/${documentData?.hash}/${documentData?.id}`;
    const qrCodeImgURL = getQRCodeImageURL(qrCodeValue);

    if (documentData?.user) {
      return `
        <br/>
        <div style="text-align: left; background: #F3F4F7; position: fixed; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px; display: flex;  align-items: center;">
            <div>
                Assinado eletronicamente por: ${cpfMask(documentData?.user?.username) || ''} • ${documentData?.user?.first_name || ''} em ${documentData?.updated_at && dateFormat(documentData?.updated_at, true)}
                <br/>
                Hash de verificação:  ${documentData?.hash || ''}
                <br/>
                Identificação:  ${documentData?.id || ''}
                <br/>
                “Essa assinatura tem respaldo legal de acordo com a lei federal n° 14.063/2020”<br />
                Consulte a autenticidade do documento em: 
                ${(window as any)._env_.REACT_APP_URL_CONSULTAR_DOC}
            </div>
            <img src="${qrCodeImgURL}" alt="QRCode" style="image-rendering: crisp-edges; -ms-interpolation-mode: nearest-neighbor; margin-left: 10px" />
        </div>
    `;
    }
    return `
    <br/>
    <div style="text-align: left; background: #F3F4F7; position: fixed; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px; display: flex;  align-items: center;">
      <div>
        Autenticidade gerada automaticamente pelo sistema em ${documentData?.updated_at && dateFormat(documentData?.updated_at, true)}
        <br/>
        Hash de verificação:  ${documentData?.hash || ''}
        <br/>
        Identificação:  ${documentData?.id || ''}
        <br/>
        “Essa assinatura tem respaldo legal de acordo com a lei federal n° 14.063/2020”<br/>
        Consulte a autenticidade do documento em:<br/> 
        ${(window as any)._env_.REACT_APP_URL_CONSULTAR_DOC}
      </div>
      <img src="${qrCodeImgURL}" alt="QRCode" style="image-rendering: crisp-edges; -ms-interpolation-mode: nearest-neighbor; margin-left: 10px" />
    </div>
    `;
  }


  return (
    <>
      <ContainerMain>
        <form onSubmit={(e) => e.preventDefault()}>
          <Card>
            <CardHeader>
              <div>
                <CardIcon>
                  <MdDescription size={20} />
                </CardIcon>
              </div>
              <Title>
                {assunto}
              </Title>
            </CardHeader>
            <>
              <Card.Body>
                <MyEditor
                  value={`
                          ${selectCabecalho ? selectCabecalho.conteudo : ''}
                          ${conteudo ?? ''}
                          ${addInfoSign()}
                        `}
                  print={true}
                  addConf={{
                    height: 400
                  }}
                />
              </Card.Body>
              <CardFooterCustom>
                {loading ? (
                  <BoxLoading>
                    <Loading />
                  </BoxLoading>
                ) : (
                  <ButtonReturn onClick={() => { history.goBack() }}>Voltar</ButtonReturn>
                )}
              </CardFooterCustom>
            </>
          </Card>
        </form>
      </ContainerMain>
    </>
  );
}

export default VisualizationDocumentResponse;