import { AxiosResponse } from 'axios';
import { RequestDetailsProps, RequestsProps } from '../@types/requests';
import { apinewforms } from './api';

export const getRequestForStatus = (
  status:
    | 'Aguardando'
    | 'Solicitado'
    | 'Em Andamento'
    | 'Finalizado'
    | 'Cancelado'
    | 'Restituído'
    | 'Encaminhado',
  filter: {
    page?: number;
    filtro?: string;
    busca?: string;
  }
): Promise<AxiosResponse<RequestsProps>> =>
  apinewforms.get(`/flow/solicitacoes/`, {
    params: { status, ...filter },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const getRequestForId = (
  identificador: string
): Promise<AxiosResponse<RequestDetailsProps>> =>
  apinewforms.get(`/flow/carregar_solicitacao`, {
    params: { identificador },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const getDocumentsRequest = (
  id: string,
  conformidade: string
): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/documentos-solicitacao/?identificador_solicitacao=${id}`,
    {
      params: { id, conformidade },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const getDocumentById = (id: string, solicitacao_id: string) =>
  apinewforms.get(`/flow/documentos-solicitacao/${id}/`, {
    params: { identificador_solicitacao: solicitacao_id },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const putDocument = (
  id: string,
  conformidade: boolean,
  solicitacao_id: string
) =>
  apinewforms.put(
    `/flow/documentos-solicitacao/${id}/`,
    {
      id,
      conformidade,
    },
    {
      params: { identificador_solicitacao: solicitacao_id },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );



export const attachDocumentRequests = (
  solicitacao_id: string,
  formData: FormData
) =>
  apinewforms.post(
    `/flow/anexo-resposta/`,
    formData,
    {
      params: { solicitacao_id },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const attachDocumentRequestsIntern = (
  identificador_solicitacao: string,
  formData: FormData
) =>
  apinewforms.post(
    `/flow/anexos-internos/`,
    formData,
    {
      params: { identificador_solicitacao },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
