import { AxiosResponse } from 'axios';
import { HomeResponse } from '../@types/home';
import { PermissionsData } from '../interfaces/permissions';
import { apinewforms, apisiseci } from './api';

export const allFluxos = (
  page: number,
  search?: string,
  active?: string
): Promise<AxiosResponse> => {
  if (search !== undefined) {
    return apinewforms.get(`/flow/fluxo-apagar/`, {
      params: {
        ativo: active,
        search: search === '' ? undefined : search,
        page,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    });
  }

  return apinewforms.get(`/flow/fluxo-apagar/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });
};

export const getFluxo = (slug_fluxo: string): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/fluxo-apagar/${slug_fluxo}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const createFluxo = (params: {
  titulo: string;
  descricao: string;
  servico_slug: string;
  ativo: boolean;
  orgao: number;
  modelo: boolean;
  automatico: boolean;
  terceiros: boolean;
  tipo: "Manual" | "Automático" | "Semi-Automático";
}): Promise<AxiosResponse> =>
  apinewforms.post(`/flow/fluxo-apagar/`, params, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const updateFluxo = (
  slug_fluxo: string,
  titulo: string,
  descricao: string,
  servico_slug: string,
  ativo: boolean,
  orgao: number,
  modelo: boolean,
  automatico: boolean,
  terceiros: boolean,
  tipo: "Manual" | "Automático" | "Semi-Automático",
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/fluxo-apagar/${slug_fluxo}/`,
    {
      slug: slug_fluxo,
      titulo,
      descricao,
      servico_slug,
      ativo,
      orgao,
      modelo,
      automatico,
      terceiros,
      tipo,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const getDataHome = (token): Promise<AxiosResponse<HomeResponse>> =>
  apinewforms.get(`/flow/home`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getMyPermissions = (
  token
): Promise<AxiosResponse<PermissionsData>> =>
  apinewforms.get(`/core/my-permissions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const postDocumentForm = (
  flow: string,
  cabecalho: string,
  conteudo: string,
  assunto: string,
  tipo: string,
  conteudo_editavel: boolean,
  sistema_assina: boolean,
  usuario_assina: boolean
): Promise<AxiosResponse> =>
  apinewforms.post(
    '/flow/formato-documento/',
    {
      flow,
      cabecalho,
      conteudo,
      assunto,
      tipo,
      conteudo_editavel,
      sistema_assina,
      usuario_assina,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const getDocumentForm = (flow_slug: string): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/formato-documento/?flow_id=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const getDocumentFormById = (
  flow_slug: string,
  document_id: string
): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/formato-documento/${document_id}/?flow_id=${flow_slug}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const putDocumentForm = (
  document_id: string,
  flow_slug: string,
  cabecalho_id: string,
  assunto: string,
  conteudo: string,
  tipo: string,
  conteudo_editavel: boolean,
  sistema_assina: boolean,
  usuario_assina: boolean,
  ativo: boolean
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/formato-documento/${document_id}/?flow_id=${flow_slug}`,
    {
      id: document_id,
      flow: flow_slug,
      assunto,
      cabecalho: cabecalho_id,
      conteudo,
      tipo,
      conteudo_editavel,
      sistema_assina,
      usuario_assina,
      ativo,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const getTramiteOrgan = (identificador: string, token: string) =>
  apinewforms.get(
    `/flow/tramitacao-pendente?identificador_solicitacao=${identificador}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const postTramite = (
  orgao: number,
  status: string,
  descricao: string,
  identificador: string,
  token: string,
  tramitacao_id: string,
  setor?: string
) =>
  apinewforms.post(
    '/flow/tramitar-solicitacao',
    {
      orgao,
      status,
      descricao,
      identificador,
      tramitacao_id,
      setor
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const duplicateFlow = (
  flow: string,
  titulo: string,
  orgao: number,
  servico: string,
  ativo: boolean,
  descricao: string,
  tokenSSO: string
) =>
  apinewforms.post(
    '/flow/duplicar/',
    {
      flow,
      titulo,
      orgao,
      servico,
      ativo,
      descricao,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const checkTask = (task_id: string, tokenSSO: string) =>
  apinewforms.post(
    '/flow/checar-task/',
    {
      task_id,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const getSevicesToOrgans = (organ_slug: string) =>
  apisiseci.get(`/cms/servicos/?orgao_slug=${organ_slug}`);

export const getAllServices = (organ_slug: string) =>
  apisiseci.get(`/cms/serv_filter/?orgao_slug=${organ_slug}`);

export const getServiceTerms = (
  tokenSSO: string,
  flow_id: string,
  page?: number
) =>
  apinewforms.get(`/flow/termo-fluxo/`, {
    params: {
      flow: flow_id,
      page,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getServiceTermsById = (
  tokenSSO: string,
  flow_id: string,
  id: string
) =>
  apinewforms.get(`/flow/termo-fluxo/${id}`, {
    params: {
      flow: flow_id,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const saveServiceTerms = (
  tokenSSO: string,
  flow_id: string,
  body: {
    titulo: string;
    versao: string;
    texto: string;
  }
) =>
  apinewforms.post(
    `/flow/termo-fluxo/?flow=${flow_id}`,
    {
      ...body,
      flow: flow_id,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );


export const getWorkflowByFlowId = (flow_slug: string): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/workflow/?flow=${flow_slug}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const createWorkflowByFlowId = (flow_slug: string, body: {
  descricao: string;
  titulo: string,
  url: string,
  credencial: string,
  flow: string,
  mapa: any,
}): Promise<AxiosResponse> =>
  apinewforms.post(
    `/flow/workflow/?flow=${flow_slug}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );


export const updateWorkflowByFlowId = ({ flow_slug, id }: { flow_slug: string, id: number },
  body: {
    descricao: string;
    titulo: string,
    url: string,
    credencial: string,
    flow: string,
    mapa: any,
  }): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/workflow/${id}/?flow=${flow_slug}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const deleteWorkflowByFlowId = ({ id, flow_slug }: { id: string, flow_slug: string }): Promise<AxiosResponse> =>
  apinewforms.delete(
    `/flow/workflow/${id}/?flow=${flow_slug}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );

export const workflowRequest = ({ page }: { page?: number }): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/execucoes-workflows/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
      params: {
        page
      }
    }
  );


export const getWebhooksRequest = (): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/webhooks/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
