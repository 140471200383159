import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton
} from "../Information/components/ManageForm/styles";
import { useHistory, useParams } from "react-router-dom";
import { actionTypes } from "../../store/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { createCredentialsList, getCredential } from "../../services/credenciais";

const NewCredential = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [credential, setCredential] = useState<any>({
    titulo: "",
    token: "",
  });



  const getCredentials = useCallback(async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await getCredential(id);
        if (data?.id) {
          setCredential({
            id: data.id,
            titulo: data.titulo,
            token: data.token,
          });
        }
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id])




  // const handleErrors = (data: any) => {
  //   let errors = {};
  //   schema.validate(data, { abortEarly: false }).catch((err) => {


  //     for (let error of err.inner) {
  //       const { path, message } = error;


  //       errors = Object.assign(
  //         errors, { [path]: message });
  //     }

  //     setErrorsFieldRequired(() => errors);

  //   });



  //   return errors;
  // }

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      // handleErrors(credential);

      const { status } = await createCredentialsList(credential);

      if (status !== 201) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'error-alt',
            title: 'Erro',
            message: 'Erro ao salvar credencial! Tente novamente',
          },
        });
        return;
      }



      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'success-alt',
          title: 'Sucesso',
          message: 'Credencial salva com sucesso!',
        },
      });
      history.push(`/credenciais`);
      return;
    } catch (err: any) {
      const { response } = err;
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Erro',
          message: response?.status === 400 ? "Credenciais com este token já existe." : 'Erro ao salvar credencial! Tente novamente',
        },
      });
      console.error(err);
    }
  }

  useEffect(() => {

    if (id) {
      getCredentials();
    }

  }, [id, getCredentials]);

  return (
    <ContainerMain>
      <form onSubmit={handleSave}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>{id ? 'Gerenciar' : 'Nova'} Credencial</Title>
          </CardHeader>

          <Card style={{ border: 0, padding: '40px 40px 0px 40px' }}>
            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Título</FormLabel>
                  <Form.Control
                    disabled={loading}
                    name="titulo"
                    required
                    value={credential.titulo} onChange={(event) => {
                      setCredential({ ...credential, titulo: event.target.value })
                    }}
                  />
                  <Form.Text className="text-muted">
                    Informe o título.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Token</FormLabel>
                  <Form.Control
                    as="textarea" rows={3}
                    name="token"
                    disabled={loading}
                    required
                    value={credential.token} onChange={(event) => {
                      setCredential({ ...credential, token: event.target.value })
                    }}

                  />
                  <Form.Text className="text-muted">
                    Informe o título.
                  </Form.Text>
                </Form.Group>
              </Col>

            </CustomRow>
          </Card>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.push('/credenciais')}
            >
              <span style={{ marginLeft: 8 }}>
                Cancelar
              </span>
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#27AB6E' }}
              type='submit'
            >
              <span style={{ marginLeft: 8 }}>
                Salvar
              </span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form>
    </ContainerMain>
  );
};

export default NewCredential;
