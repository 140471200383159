import { Card, Container, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;
export const CardBody = styled(Card.Body)`
  padding: 40px;
  @media screen and (max-width: 999px) {
    padding: 20px;
  } ;
`;
export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const FormGroupCustom = styled(Form.Group)`
  padding: 0px 40px;
  margin: 20px 0px;
`;

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => (required ? 'inline' : 'none')};
  }
`;

export const CardFooterCustom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  flex: 0.5;
  border: 1px solid #e1e6ef;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #506176;
  height: 48px;
  background-color: #ffffff;
`;

export const ButtonSave = styled.button`
  flex: 0.5;
  border: 1px solid #e1e6ef;
  height: 48px;
  background-color: #27ab6e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
`;

export const BoxLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
