import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import {
  MdInfo,
} from "react-icons/md";
import { useParams, useHistory } from "react-router-dom";
import Loading from "../../Components/Loading";
import {
  CardHeader,
  Title,
  InfoTitle,
  InformationDescription,
  Box,
  CardIcon,
  CardFooterCustom,
  ButtonReturn,
} from "./style";



import dateFormatToBR from "../../utils/dateFormat";
import { getCredential } from "../../services/credenciais";

const CredentialInformation = () => {
  const [credentialInfo, setCredentialInfo] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();


  const history = useHistory();

  const getCredentialData = async () => {
    try {
      setLoading(true);
      const { data } = await getCredential(id);
      setCredentialInfo(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    if (id) {
      getCredentialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {loading ? (
        <div className="d-flex mt-4 justify-content-center">
          <Loading />
        </div>
      ) : undefined}
      <Container style={{ marginTop: 56 }}>
        {credentialInfo && (
          <>
            <Card>
              <CardHeader>
                <Box>
                  <CardIcon>
                    <MdInfo size={20} />
                  </CardIcon>
                  <Title>Informações da Credencial</Title>
                </Box>
              </CardHeader>
              <Card.Body>
                <Row>
                  <Col sm={4}>
                    <InfoTitle>Título</InfoTitle>
                    <InformationDescription>
                      {credentialInfo?.titulo || ''}
                    </InformationDescription>
                  </Col>
                  <Col sm={4}>
                    <InfoTitle>Criado por</InfoTitle>
                    <InformationDescription>
                      {credentialInfo?.user_data?.nome || ''}
                    </InformationDescription>
                  </Col>
                  <Col>
                    <InfoTitle>Adicionado em</InfoTitle>
                    <InformationDescription>
                      {dateFormatToBR(credentialInfo.created_at, true)}
                    </InformationDescription>
                    <InfoTitle>Atualizado</InfoTitle>
                    <InformationDescription>
                      {dateFormatToBR(credentialInfo.updated_at, true)}
                    </InformationDescription>
                  </Col>
                </Row>
              </Card.Body>
              <CardFooterCustom>
                <ButtonReturn onClick={() => history.push('/credenciais')}>
                  Voltar
                </ButtonReturn>
              </CardFooterCustom>
            </Card>
          </>
        )}
      </Container>
    </>
  );
};

export default CredentialInformation;
