import { apinewforms } from './api';

export const postField = (
  ordem: string,
  titulo: string,
  texto_interno: string,
  formulario: string,
  mascara: number | string,
  validador: string,
  tipo: string,
  texto_ajuda: string,
  obrigatorio: boolean,
  alternativas: any,
  tipo_alternativa?: string,
  limite_replicacoes?: number
) => {
  return apinewforms.post(
    '/flow/campos-apagar/',
    {
      ordem,
      titulo,
      texto_interno,
      formulario,
      mascara,
      validador,
      tipo,
      tipo_alternativa,
      texto_ajuda,
      limite_replicacoes,
      obrigatorio,
      alternativas,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
};

export const getFields = (form_slug: string) => {
  return apinewforms.get(`/flow/campos-apagar/?formulario=${form_slug}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });
};

export const getFieldsByFlow = (flow_slug: string) => {
  return apinewforms.get(`/flow/campos-apagar/?flow=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });
};

export const getSelectorsByFlow = (flow_slug: string) => {
  return apinewforms.get(`/flow/seletores/?flow=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });
};

export const deleteField = (id: number, form_slug: string) => {
  return apinewforms.delete(
    `/flow/campos-apagar/${id}/?formulario=${form_slug}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
};

export const updateField = (
  id: number,
  form_slug: string,
  ordem: string,
  titulo: string,
  texto_interno: string,
  texto_ajuda: string,
  obrigatorio: boolean,
  alternativas: any,
  tipo: string,
  validador?: string,
  mascara?: number | string,
  tipo_alternativa?: string,
  limite_replicacoes?: number
) => {
  return apinewforms.put(
    `flow/campos-apagar/${id}/?formulario=${form_slug}`,
    {
      ordem,
      titulo,
      texto_interno,
      texto_ajuda,
      obrigatorio,
      alternativas,
      tipo,
      limite_replicacoes,
      formulario: form_slug,
      validador,
      mascara,
      tipo_alternativa,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
};
