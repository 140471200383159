import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Form } from 'react-bootstrap';
import {
  ContainerMain,
  CardHeader,
  Title,
  FormGroupCustom,
  FormLabelCustom,
  CardFooterCustom,
  CardIcon,
  ButtonSave,
  ButtonCancel,
  LegendText
} from './styles';
import { MdDelete, MdEdit, } from 'react-icons/md';
import ModalCodeValidate from './Components/ModalCodeValidate';
import { useStateValue } from '../../providers/StateProvider';
import { actionTypes } from '../../store/reducer';
import CancelModal from '../../Components/CancelModal';
import { getTramiteOrgan, postTramite } from '../../services/flow';
import MyEditor from '../../Components/MyEditor';
import { requestCodeSign, sendCodeSignToValidate } from '../../services/signature';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { AttachedUploadIcon, DocAttached, RemoveAttached, TextDocAttached } from '../AttachDocument/styles';
import { attachDocumentRequestsIntern } from '../../services/requests';
import { getSetorById } from '../../services/setor';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type Params = {
  identificador: string;
};

const NewProceed = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const [showModalCode, setShowModalCode] = useState(false);

  const { identificador } = useParams<Params>();

  const [descricao, setDescricao] = useState<string>('');
  const [organ, setOrgan] = useState(null);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [requestedCode, setRequestedCode] = useState<{ message: string, state: string }>(null);
  const [invalidCode, setInvalidCode] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [tramitedDoc, setTramitedDoc] = useState(null);
  const [hasCheck, setHasCheck] = useState<boolean>(false);
  const [files, setFiles] = useState<FileObject[]>([]);
  const [setorList, setSetorList] = useState<any>(null);
  const [setorSelected, setSetorSelected] = useState<string>('');
  const [open, setOpen] = useState(false);

  const getSetorbyIdOrgan = async (orgao_id: number) => {
    const setores = await getSetorById(orgao_id);
    setSetorList(setores.data);
  }

  useEffect(() => {
    const getSetorByIdOrg = async () => {
      await getSetorbyIdOrgan(organ?.orgao?.id)
      /*   if (state?.process) {
          setSetorSelected(process.setor)
        } */
    }
    getSetorByIdOrg();

  }, [organ])


  console.log(organ, "organ")

  const [showMessageError] = useState<any>();
  const handleCancel = () => {
    setShowModalCancel(true);
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const [, setMessageErrorUpload] = useState<
    undefined | string
  >(undefined);
  const [, setIsErrorInUpload] = useState(false);
  const handleUploadsAlert = (variant: string) => {
    if (variant === 'error') {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  };

  const removeFile = (file: FileObject) => {

    const fileFilter = files.filter(
      (fileFiltering) => fileFiltering.data !== file.data
    );
    setFiles(fileFilter);

    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Excluído',
        message: 'Documento excluído com sucesso.',
      },
    });
  };

  const getOrgan = async () => {
    const token: string | null = localStorage.getItem('gov_access_token');
    const { data } = await getTramiteOrgan(identificador, token);
    setOrgan(data);
    setStatus(data.status);
  }

  useEffect(() => {
    getOrgan();
    const dataStorage = localStorage.getItem("gov_user_data");
    const userData = JSON.parse(dataStorage);

    setEmail(userData?.contato?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseCancel = () => {
    history.goBack();
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Cancelado',
        message: 'Documento não foi criado.',
      },
    });
  }

  const handleSave = async () => {
    try {
      const token: string | null = localStorage.getItem('gov_access_token');
      const { data } = await postTramite(organ.orgao.id, status, descricao, identificador, token, organ.id, setorSelected);
      setTramitedDoc(data);
      setShowModalCode(true);
      await requestNewCodeEmail();
    } catch (err) {
      console.error(err);
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Cancelado',
          message: 'Documento não foi criado.',
        },
      });
    }
  };

  const requestNewCodeEmail = async () => {
    try {
      const dataStorage = localStorage.getItem("gov_user_data");
      const token: string | null = localStorage.getItem('gov_access_token');
      const userData = JSON.parse(dataStorage);
      if (token) {
        const { data } = await requestCodeSign(userData.cpf, userData.contato.email, token);
        setRequestedCode(data);
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success',
            title: 'Sucesso',
            message: 'Código enviado para o email',
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleSaveAttachments = async () => {
    try {
      const promises = files?.map((fileObj) => {
        let form = new FormData();
        form.append('solicitacao', organ.id);
        form.append('assunto', organ.atividade);
        form.append('descricao', organ.descricao);
        form.append('arquivo', fileObj.file);

        return attachDocumentRequestsIntern(identificador, form);
      });

      if (!promises) {
        throw new Error("No files to save.");
      }

      const results = await Promise.all(promises);

      if (results.some(result => result.data)) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success',
            title: 'Sucesso',
            message: 'Documento(s) anexado(s) com sucesso!',
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Cancelado',
          message: 'Documento não foi criado.',
        },
      });
      throw err;
    }
  }

  const processSignature = async (code: string) => {
    try {
      const token: string | null = localStorage.getItem('gov_access_token');
      const response = await sendCodeSignToValidate({
        code,
        internal_state: identificador,
        identificador: tramitedDoc.identificador,
        state: requestedCode.state,
        token,
        client_id: (window as any)._env_.REACT_APP_FORMS_CLIENT_ID,
        tipo: 'Tramitacao'
      });

      if (response.status === 200) {
        await handleSaveAttachments();

        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success',
            title: 'Sucesso',
            message: 'Tramitação feita com sucesso',
          },
        });
        history.push(`/solicitacoes/detalhes/${identificador}`);
      }
    } catch (err) {
      console.error(err);
      if (err?.response?.status === 404) {
        setInvalidCode(true);
      }
    }
  }

  useEffect(() => {
    if (!hasCheck) {
      setStatus(organ?.status);
    }
  }, [hasCheck, organ]);

  return (
    <>
      <ModalCodeValidate
        email={email}
        hasValidCode={invalidCode}
        setHasValidCode={setInvalidCode}
        sendEmail={requestNewCodeEmail}
        handleSave={processSignature}
        show={showModalCode}
        setShow={setShowModalCode}
      />
      <ContainerMain>
        <form onSubmit={(e) => e.preventDefault()}>
          <Card>
            <CardHeader>
              <div>
                <CardIcon>
                  <MdEdit size={20} />
                </CardIcon>
              </div>
              <Title>
                Tramitar
              </Title>
            </CardHeader>
            <Card.Body>
              <FormGroupCustom className="mb-3" controlId="">
                <FormLabelCustom required>Etapa/Órgão</FormLabelCustom>
                <Form.Select
                  required
                  disabled
                >
                  {organ && organ.orgao && (
                    <option selected>{`${organ.ordem} ${organ.atividade}`} / {`${organ.orgao.nome} - ${organ.orgao.sigla}`}</option>
                  )}
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe a etapa/órgão
                </Form.Text>
              </FormGroupCustom>
              <FormGroupCustom className="mb-3" controlId="">
                <Form.Check
                  label="Deseja restituir ou cancelar essa solicitação?"
                  type="checkbox"
                  checked={hasCheck}
                  onChange={(event) => setHasCheck(event.target.checked)}
                />
                <LegendText>
                  Restituído: A restituição serve para que o solicitante forneça dados complementares necessários à continuidade da solicitação
                </LegendText>
                <br />
                <LegendText>
                  Cancelado pelo atendente: O atendente realiza o cancelamento da solicitação.
                </LegendText>
              </FormGroupCustom>

              <FormGroupCustom className="mb-3" controlId="">
                <FormLabelCustom required>Status</FormLabelCustom>
                {hasCheck ? (
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    <option value="Restituído">Restituído</option>
                    <option value="Cancelado pelo atendente">Cancelado pelo atendente</option>
                  </Form.Select>
                ) : (
                  <Form.Select
                    required
                    disabled
                  >
                    {status ? (
                      <option selected>{status}</option>
                    ) : (
                      <option selected>Carregando...</option>
                    )}
                  </Form.Select>
                )}
                <Form.Text className="text-muted">
                  Informe o status da tramitação
                </Form.Text>
              </FormGroupCustom>

              {status === "Encaminhado" && (
                < FormGroupCustom className="mb-3" controlId="">
                  <FormLabelCustom required>Setor</FormLabelCustom>
                  <Form.Select
                    required
                    onChange={(e) => {
                      const findSetor = setorList?.find((setor) => setor.id === e.target.value);
                      setSetorSelected(findSetor?.id || "");
                    }}
                    value={setorSelected || ""}
                  >
                    <option value="">Selecione</option>
                    {setorList && setorList.map((setor) => (
                      <option key={setor.id} value={setor.id}>{setor.nome}</option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Informe o setor
                  </Form.Text>
                </FormGroupCustom>
              )
              }
              <FormGroupCustom>
                <FormLabelCustom required>Resumo</FormLabelCustom>
                <MyEditor
                  value={descricao}
                  setValue={setDescricao}
                  addConf={{
                    height: 300
                  }}
                />
                <Form.Text className="text-muted">
                  Informe o resumo da tramitação
                </Form.Text>
                {/* {showMessageError && !descricao && (
                  <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>
                    Este campo não pode ser vazio.
                  </p>
                )} */}
              </FormGroupCustom>
              <FormGroupCustom>
                <FormLabelCustom >Documento</FormLabelCustom>
                {files.length === 0 && (<DropzoneAreaBase
                  onAdd={(filesUpload) => {
                    setFiles(filesUpload);
                    setMessageErrorUpload(undefined);
                  }}
                  fileObjects={files}
                  acceptedFiles={["image/jpeg", "image/png", ".pdf"]}
                  showPreviewsInDropzone={false}
                  dropzoneText="Escolha um arquivo ou arraste-o aqui"
                  showFileNames={false}
                  showFileNamesInPreview={false}
                  showAlerts={false}
                  filesLimit={30}
                  maxFileSize={31457280}
                  onAlert={(_, variant) => handleUploadsAlert(variant)}
                />)}
                {showMessageError?.arquivo && (
                  <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>
                    Este campo não pode ser vazio.
                  </p>
                )}
                {files && files.map((file, index) => (
                  <DocAttached key={index}>
                    <TextDocAttached>
                      <AttachedUploadIcon />
                      {`${file?.file?.name} (${formatBytes(file.file?.size)})`}

                    </TextDocAttached>
                    <RemoveAttached onClick={(): void => removeFile(file)}><MdDelete /></RemoveAttached>
                  </DocAttached>))}
              </FormGroupCustom>
            </Card.Body>
            <CardFooterCustom>
              <ButtonCancel
                onClick={handleCancel}>
                Cancelar
              </ButtonCancel>
              <ButtonSave
                onClick={status === "Encaminhado" ? () => setOpen(true) : handleSave}
              >
                Salvar
              </ButtonSave>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
              >
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Você tem certeza de que o setor selecionado é o correto?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      handleSave();
                      setOpen(false);
                    }}
                    color="primary"
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
            </CardFooterCustom>
          </Card>
        </form>
      </ContainerMain >
      <CancelModal
        show={showModalCancel}
        handleShow={setShowModalCancel}
        title="Cancelar documento"
        description='Tem certeza que deseja cancelar esta tramitação? Essa ação não pode ser desfeita.'
        handleActionOk={handleCloseCancel}
      />
    </>
  );
}

export default NewProceed;