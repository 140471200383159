import { AxiosResponse } from 'axios';
import { apisiseci, apisso } from './api';
import { UserData, UserDataSecondary } from '../interfaces/user';

export const saveTokens = (tokenSISECI: string): void => {
  localStorage.setItem('gov_access_token', tokenSISECI);
};

export const clearStorage = (): void => {
  localStorage.clear();
};

export const getPathToRedirectLogin = (): string => {
  if ((window as any)._env_.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    return `${(window as any)._env_.REACT_APP_SSO}/sso?client_id=${(window as any)._env_.REACT_APP_CLIENT_ID
      }`;
  }

  return `${(window as any)._env_.REACT_APP_SSO_SECUNDARY}/auth?client_id=${(window as any)._env_.REACT_APP_CLIENT_ID
    }&response_type=${(window as any)._env_.REACT_APP_RESPONSE_TYPE
    }&redirect_uri=${(window as any)._env_.REACT_APP_REDIRECT_URI}`;
};

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage();
  localStorage.setItem('pathname_redirect_gov', pathname);
  (window as any).open(getPathToRedirectLogin(), '_self');
};

export const logout = async () => {
  if ((window as any)._env_.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    clearStorage();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (window as any).open(
      `${(window as any)._env_.REACT_APP_SSO}/sso/sair?client_id=${(window as any)._env_.REACT_APP_CLIENT_ID
      }&redirect_uri=${window.location.href}`,
      '_self'
    );
  } else {
    clearStorage();
    (window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${(window as any)._env_.REACT_APP_SSO_SECUNDARY}/logout?client_id=${(window as any)._env_.REACT_APP_CLIENT_ID
      }&response_type=${(window as any)._env_.REACT_APP_RESPONSE_TYPE
      }&redirect_uri=${window.location.href}`,
      '_self'
    );
  }
};

export const getDataUserSso = (token: string): Promise<AxiosResponse> =>
  apisso.get('/cidadaos/pro', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getDataUserSecondary = (
  token: string,
  access_token_secondary: string
): Promise<AxiosResponse> =>
  apisiseci.get('/profile/externo', {
    headers: {
      Authorization: `Bearer ${token}`,
      'Authorization-Externo': access_token_secondary,
    },
  });

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    '/validar_cidadao/',
    {
      code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const loginSigp = (
  code: string,
  redirect_uri?: string
): Promise<AxiosResponse> => {
  if ((window as any)._env_.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    return apisso.post('/validar/', {
      code,
      client_id: (window as any)._env_.REACT_APP_CLIENT_ID,
      client_secret: (window as any)._env_.SSO_CLIENT_SECRET,
      redirect_uri: `${(window as any)._env_.REACT_APP_URL}/login/callback/`,
    });
  }
  return apisiseci.post(
    '/login/externo/',
    {
      code,
      redirect_uri:
        redirect_uri || (window as any)._env_.REACT_APP_REDIRECT_URI,
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: (window as any)._env_.REACT_APP_API_SISECI_KEY,
      },
    }
  );
};

const secondaryDataUserAdapter = (data: UserDataSecondary): UserData => {
  const dataF = {
    nome: data.name || '',
    data_nascimento: data.dataNascimento || '',
    nome_mae: data.nomeMae || '',
    cpf: data.preferred_username || data.username || data.cpf || '',
    contato: {
      email: data.email || '',
    },
  };
  return dataF as UserData;
};

export const getUserData = async (
  token: string,
  access_token_secondary?: string
) => {
  if ((window as any)._env_.REACT_APP_SSO_LOGIN_PROVIDER === 'primary') {
    const { data } = await getDataUserSso(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data[0];
  }

  const { data } = await getDataUserSecondary(access_token_secondary, token);
  return secondaryDataUserAdapter(data);
};

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = localStorage.getItem('pathname_redirect_gov');

  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`
    );
    console.log({ data });
    if (data.access_token) {
      saveTokens(data.access_token);
      const spathname = pathname === '/' ? '/home' : pathname;
      history.push(spathname || '/home', {
        message: data.message ? data.message : '',
      });
    }
  } catch (err) {

    //clearStorage();
    // history.push(pathname || '/', {
    //   message: 'Erro ao processar o login',
    // });
  }
};

export const getImageProfile = async (token: string) => {
  return apisso.get('cidadaos/foto/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
