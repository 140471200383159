import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Form, Tabs, Tab } from 'react-bootstrap';
import Loading from '../../Components/Loading';

import {
  ContainerMain,
  CardHeader,
  Title,
  FormGroupCustom,
  FormLabelCustom,
  CardFooterCustom,
  CardIcon,
  ButtonSave,
  ButtonCancel,
  BoxLoading,
  CardBody
} from './styles';
import { MdDescription, } from 'react-icons/md';
import ModalCodeValidate from './Components/ModalCodeValidate';
import CancelModal from '../../Components/CancelModal';
import { useStateValue } from '../../providers/StateProvider';
import { actionTypes } from '../../store/reducer';
import { createRequestDocument, getVisualizationResponse, updateRequestDocument } from '../../services/documents';
import { getOrganHeader } from '../../services/organ';
import MyEditor from '../../Components/MyEditor';

interface HeaderProps {
  conteudo: string;
  created_at: string;
  id: string;
  orgao: number;
  titulo: string;
  updated_at: string;
  user: any;
}
export interface DocumentProps {
  assunto: string;
  cabecalho: string;
  conteudo: string;
  created_at: string;
  hash?: string;
  id: string;
  solicitacao: number;
  updated_at: string;
  user: any;
}

const AttachDocument = () => {
  const history = useHistory();
  const { solicitacao_id, orgao_id, solicitacao, id_documento } = useParams<{ solicitacao_id: string; orgao_id: string; solicitacao?: string, id_documento: string }>();
  const [, dispatch] = useStateValue();
  const [showModalCode, setShowModalCode] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [assunto, setAssunto] = useState<string>('');
  const [headers, setHeaders] = useState<HeaderProps[]>();
  const [conteudo, setConteudo] = useState<string>('');
  const [selectCabecalho, setSelectCabecalho] = useState<any>();
  const [documentSaved, setDocumentSaved] = useState<DocumentProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const [existDocument, setExistDocument] = useState(null);

  const handleCancel = () => {
    setShowModalCancel(true);
  }

  const handleCloseCancel = () => {
    history.goBack();
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Cancelado',
        message: 'Documento não foi criado.',
      },
    });
  }

  const handleSave = () => {
    setShowModalCode(true);
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: 'Documento foi criado com sucesso.',
      },
    });
  }

  const handleCreatedDocument = async () => {
    try {
      setLoading(true);
      if (existDocument) {
        const { data } = await updateRequestDocument(parseInt(solicitacao_id), assunto, conteudo, selectCabecalho.id, solicitacao, id_documento);
        setDocumentSaved(data);
        setShowModalCode(true);
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success-alt',
            title: 'Sucesso',
            message: 'Documento foi atualizado com sucesso.',
          },
        });
      } else {
        const { data } = await createRequestDocument(
          parseInt(solicitacao_id),
          assunto,
          conteudo,
          selectCabecalho.id
        );
        setDocumentSaved(data);
        handleSave();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getAllApi = async () => {
    const organ = await getOrganHeader(orgao_id);
    setHeaders(organ.data.results);
    if (id_documento) {
      const document = await getVisualizationResponse(id_documento, solicitacao);
      setExistDocument(document.data)
    }
  }

  useEffect(() => {
    getAllApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (existDocument) {
      console.log(existDocument)
      setAssunto(existDocument.assunto);
      setSelectCabecalho(existDocument.cabecalho)
      setConteudo(existDocument.conteudo)
    }
  }, [existDocument]);



  return (
    <>
      {documentSaved && (
        <ModalCodeValidate documents={documentSaved} show={showModalCode} setShow={setShowModalCode} />
      )}
      <ContainerMain>
        <form onSubmit={(e) => e.preventDefault()}>
          <Card>
            <CardHeader>
              <div>
                <CardIcon>
                  <MdDescription size={20} />
                </CardIcon>
              </div>
              <Title>
                {existDocument ? 'Atualizar ' : 'Criar '}documento
              </Title>
            </CardHeader>
            <Tabs defaultActiveKey="edit" className="mb-3">
              <Tab eventKey="edit" title="Editar">
                <>
                  <Card.Body>
                    <FormGroupCustom className="mb-3" controlId="">
                      <FormLabelCustom required>Assunto</FormLabelCustom>
                      <Form.Control
                        required
                        value={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                      >
                      </Form.Control>
                      <Form.Text className="text-muted">
                        Informe o assunto do documento
                      </Form.Text>
                    </FormGroupCustom>
                    <FormGroupCustom className="mb-3" controlId="">
                      <FormLabelCustom required>Cabeçalho</FormLabelCustom>
                      <Form.Select onChange={(e) => {
                        const findHeader = headers?.find((header) => header.id === e.target.value);
                        setSelectCabecalho(findHeader);
                      }}>
                        <option value="none">Selecione</option>
                        {headers?.map((header) => (
                          <option selected={header.id === selectCabecalho?.id} value={header.id}>{header.titulo}</option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe o cabeçalho do documento
                      </Form.Text>
                    </FormGroupCustom>
                    <FormGroupCustom>
                      <FormLabelCustom required>Conteúdo</FormLabelCustom>
                      <MyEditor
                        value={conteudo}
                        setValue={setConteudo}
                        modeEdit='full'
                        addConf={{
                          height: 300
                        }}
                      />
                      <Form.Text className="text-muted">
                        Informe o conteúdo do documento
                      </Form.Text>
                    </FormGroupCustom>
                  </Card.Body>
                  <CardFooterCustom>
                    <ButtonCancel
                      onClick={handleCancel}>
                      Cancelar
                    </ButtonCancel>
                    {loading ? (
                      <BoxLoading>
                        <Loading />
                      </BoxLoading>
                    ) : (
                      <ButtonSave
                        disabled={
                          !assunto ||
                          !selectCabecalho
                        }
                        onClick={handleCreatedDocument}
                      >
                        {existDocument ? 'Atualizar' : 'Salvar'}
                      </ButtonSave>
                    )}
                  </CardFooterCustom>
                </>
              </Tab>
              <Tab eventKey="preview" title="Prévia">
                <CardBody>
                  <MyEditor
                    value={
                      `${selectCabecalho ? selectCabecalho?.conteudo : ''}
                      ${conteudo ?? ''}`}
                    disabled={true}
                    addConf={{
                      height: 600,
                    }}
                  />
                </CardBody>
              </Tab>
            </Tabs>
          </Card>
        </form>
      </ContainerMain>

      <CancelModal
        show={showModalCancel}
        handleShow={setShowModalCancel}
        title="Cancelar documento"
        description='Tem certeza que deseja cancelar este documento? Essa ação não pode ser desfeita.'
        handleActionOk={handleCloseCancel}
      />
    </>
  );
}

export default AttachDocument;