import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdClose, MdSave, MdSettings } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  getSectorDataNoPaginate,
  putSectorUser,
} from "../../../services/organ";
import { SelectFilter } from "./style";
import { BoxCloseModal, HeaderModal, Info, TitleModal } from "./style";
interface Props {
  organId: number;
  userId: number;
  show: boolean;
  handleReload: ({ page }) => void;
  handleShow: () => void;
  sectorSelected?: string;
}
const ModalSector = ({
  show,
  handleShow,
  handleReload,
  organId,
  userId,
  sectorSelected,
}: Props) => {
  const [sectors, setSectors] = useState<any[]>([]);
  const [selected, setSelected] = useState();

  const getSectors = async () => {
    try {
      const { data } = await getSectorDataNoPaginate(organId);
      setSectors(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSector = async () => {
    try {
      await putSectorUser(userId, organId, selected);
    } catch (error) {
      console.log("error", error);
    } finally {
      handleReload({ page: 1 });
      handleShow();
    }
  };

  return (
    <Modal show={show} onHide={handleShow} centered size="lg">
      <HeaderModal>
        <Info>
          <MdSettings size={22} />
          <TitleModal>{sectorSelected ? `Editar` : `Adicionar`} setor do usuário</TitleModal>
        </Info>
        <BoxCloseModal onClick={handleShow}>
          <MdClose size={22} />
        </BoxCloseModal>
      </HeaderModal>
      <Modal.Body>
        <Form.Label required>Selecione um setor:</Form.Label>
        <SelectFilter
          onChange={(event) => {
            setSelected(event.target.value);
          }}
        >
          {sectors.map((selector: any) => (
            <option value={selector.id} selected={selector.id === sectorSelected}>{selector.nome}</option>
          ))}
        </SelectFilter>
        <Box
          style={{
            display: "flex",
            marginLeft: 0,
            marginTop: 20,
          }}
        >
          <Button
            id="saveData"
            style={{
              backgroundColor: "#27AB6E",
              border: 0,
              display: "flex",
              alignItems: "center",
              marginRight: 20,
            }}
            type="submit"
            onClick={handleChangeSector}
          >
            <MdSave size={20} />
            <span style={{ marginLeft: 8 }}>Salvar</span>
          </Button>
          <Button
            className="button"
            style={{
              backgroundColor: "#EC4C47",
              border: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleShow}
          >
            <RiCloseCircleFill size={20} />
            <span style={{ marginLeft: 8 }}>Cancelar</span>
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSector;
