import { apinewforms } from './api';

export const getProcessing = (flow_slug: string) => {
  return apinewforms.get(`/flow/tramitacao-interna/?flow=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });
};

export const postProcessing = (
  flow_slug: string,
  ordem: string,
  atividade: string,
  orgao: number,
  ativo: boolean,
  setor: string,
  status: string,
  descricao?: string,
) => {
  return apinewforms.post(
    `/flow/tramitacao-interna/`,
    {
      ordem,
      atividade,
      flow: flow_slug,
      orgao,
      ativo,
      setor,
      status,
      descricao,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
};

export const putProcessing = (
  flow_slug: string,
  id: number,
  ordem: string,
  atividade: string,
  orgao: number,
  ativo: boolean,
  setor: string,
  status: string,
  descricao?: string,
) => {
  return apinewforms.put(
    `/flow/tramitacao-interna/${id}/?flow=${flow_slug}`,
    {
      id,
      ordem,
      atividade,
      flow: flow_slug,
      orgao,
      ativo,
      setor,
      status,
      descricao,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    }
  );
};
