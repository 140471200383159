/* eslint-disable no-fallthrough */
import { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import {
  MdInfo,
  MdAddCircle,
  MdMoreVert,
  MdAssignment,
  MdBuild,
  MdSettings,
  MdCheckCircle,
} from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { getFluxoProps, getFormsProps } from "../../@types/information";
import { DocumentsProps } from "../../@types/document";
import Loading from "../../Components/Loading";
import dateFormatToBR from "../../utils/dateFormat";
import Status from "../../Components/Status";
import { getFluxo, getServiceTerms, getWorkflowByFlowId } from "../../services/flow";

import {
  CardHeader,
  Title,
  InfoTitle,
  InformationDescription,
  CustomTable,
  DropdownCustom,
  DropdownItemCustom,
  ButtonReturn,
  Box,
  CardIcon,
  CardFooterCustom,
  ContainerButton,
  SelectFilter,
} from "./styles";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { getProcessing, putProcessing } from "../../services/processing";
import { getDocumentList } from "../../services/documents";
import { getService } from "../../services/services";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import CancelModal from "../../Components/CancelModal";
import { IoMdCloseCircle } from "react-icons/io";
import { updateDocumentInFlow, updateFormInFlow } from "../../services/form";
import { FaCopy, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import Paginate from "../../Components/Paginate";
import InformationModal from "../../Components/InformationModal";
import TermModal from "../../Components/TermModal";
import DeleteModal from "./components/DeleteModal";
import InformationWorkflowModal from "./components/InformationModal";

type CurrentStatusProps = 'ativado' | 'desativado';
type CurrentActionTitleProps = 'Formulário' | 'Tramite' | 'Documento';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& .MuiTab-wrapper": {
      textTransform: "capitalize",
      fontSize: 16,
      fontWeight: 500,
      textAlign: "left",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
  },
  tabSelected: {
    border: "1px solid #E1E6EF",
    borderRadius: "4px 4px 0px 0px",
    color: "#004F9F",
  },
  indicatorTab: {
    backgroundColor: "transparent",
  },
  labelIcon: {
    minHeight: 50,
  },
}));

const allDeleteActionTitles = {
  forms: {
    title: 'Formulário',
  },
  process: {
    title: 'Tramitação'
  },
  document: {
    title: 'Documento'
  }
}

const Information = () => {
  const [, dispatch] = useStateValue();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [showModalDeleteWorkflow, setShowModalDeleteWorkflow] = useState(false);
  const [showModalDetailWorkflow, setShowModalDetailWorkflow] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [showModalInformation, setShowModalInformation] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);
  const [dataSelected, setDataSelected] = useState<
    getFluxoProps | getFormsProps
  >();
  const [termSelected, setTermSelected] = useState<
    any
  >();
  const [flowInformationData, setflowInformationData] =
    useState<getFluxoProps>();
  const { flow_slug } = useParams<{ flow_slug: string }>();
  const [loadingWorkflow, setLoadingWorkflow] = useState(false);
  const [workflow, setWorkflow] = useState<any>();
  const [workflowSelected, setWorkflowSelected] = useState<any>();
  const [processing, setProcessing] = useState([]);
  const [documents, setDocuments] = useState<DocumentsProps[]>();
  const [terms, setTerms] = useState<any>();
  const [serviceIdentify, setServiceIdentify] = useState<string>("");
  const [filterBy, setFilterBy] = useState("all");
  const [filterTramBy, setTramFilterBy] = useState("all");
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [formDataSelected, setFormDataSelected] = useState(null);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [descriptionCancel, setDescriptionCancel] = useState<string | null>();
  const [currentFlowActive, setCurrentFlowActive] = useState<'error' | 'success'>();
  const [currentTypeCancel, setCurrentTypeCancel] = useState<'process' | 'forms' | 'document' | null>();

  const classes = useStyles();

  const handleCloseModal = () => {
    setShowModalInformation(false);
    setDataSelected(null);
  };

  const handleCloseModalDetailsWorkflow = () => {
    setShowModalDetailWorkflow(false);
    setWorkflowSelected(null);
  };

  const handleCloseTermModal = () => {
    setShowTermModal(false);
    setTermSelected(null);
  };

  type TermProps = {
    page?: number;
  }

  const getTerms = async (termProps?: TermProps) => {

    const page = termProps?.page || 1;

    try {
      const tokenSSO: string | null = localStorage.getItem('gov_access_token');
      setLoading(true);
      const { data } = await getServiceTerms(tokenSSO, flow_slug, page);
      setTerms(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const getProcessingApi = async () => {
    try {
      setLoadingProcess(true);
      const { data } = await getProcessing(flow_slug);
      setProcessing(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoadingProcess(false);
    }
  };

  const getWorkflow = async (flow_slug: string) => {
    try {
      setLoadingWorkflow(true);
      const { data } = await getWorkflowByFlowId(flow_slug);
      setWorkflow(data);
    } catch (error) {
      console.log(error);

    } finally {
      setLoadingWorkflow(false);
    }
  };

  const handleGetFlow = async () => {
    try {
      setLoading(true);
      const { data } = await getFluxo(flow_slug);
      setflowInformationData(data);
      if (data?.servico_slug) {
        const service = await getService(data?.servico_slug);
        const { results } = service.data;
        if (results?.[0]) {
          setServiceIdentify(results[0].titulo);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDocuments = async () => {
    try {
      const { data } = await getDocumentList(flow_slug);
      setDocuments(data.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToastMessage = (active: CurrentStatusProps, type: CurrentActionTitleProps) => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: `${type} ${active}!`,
      },
    });
  }

  const handleModalCancel = useCallback(() =>
    setShowModalCancel((oldStateModal) => !oldStateModal), []);

  const handleEmptyData = () => {
    setFormDataSelected(null);
    setDescriptionCancel(null);
  }

  const handleOptionUpdateForm = async ({
    slug,
    titulo,
    descricao,
    ordem,
    currentActive
  }) => {
    updateFormInFlow(
      flow_slug,
      slug,
      titulo,
      descricao,
      currentActive,
      1,
      ordem,
    );
  }

  const handleOptionUpdateProcess = async ({
    process_id,
    ordem,
    atividade,
    orgao,
    currentActive,
    setor,
    status
  }) => {
    putProcessing(
      flow_slug,
      process_id,
      ordem,
      atividade,
      orgao,
      currentActive,
      setor,
      status
    );
  }

  const handleDocumentUpdateProcess = async ({
    flow_id,
    document_id,
    active
  }) => {
    updateDocumentInFlow(
      flow_id,
      document_id,
      active
    )
  }

  const handleActiveItem = async (item: any, type: 'process' | 'forms' | 'document') => {
    try {
      if (item?.ativo) {
        setLoadingCancel(true);
        switch (type) {
          case 'forms':
            await handleOptionUpdateForm({
              slug: item.slug,
              titulo: item.titulo,
              descricao: item.descricao,
              ordem: item.ordem.toString(),
              currentActive: false
            });
            handleToastMessage('desativado', 'Formulário');
            handleGetFlow();
            getProcessingApi();
            handleEmptyData();
            break;
          // @ts-ignore
          case 'process':
            await handleOptionUpdateProcess({
              process_id: item.id,
              ordem: item.ordem,
              atividade: item.atividade,
              orgao: item.orgao,
              currentActive: false,
              setor: item.setor,
              status: item.status
            });
            handleToastMessage('desativado', 'Tramite');
            handleGetFlow();
            getProcessingApi();
            handleEmptyData();
            break;
          case 'document':
            await handleDocumentUpdateProcess({
              flow_id: item.flow,
              document_id: item.id,
              active: false,
            });
            handleToastMessage('desativado', 'Documento');
            await handleGetFlow();
            await getProcessingApi();
            await getDocuments();
            handleEmptyData();
            break;
          default:
            break;
        }
      } else {
        setLoadingCancel(true);
        switch (type) {
          // @ts-ignore
          case 'forms':
            await handleOptionUpdateForm({
              slug: item.slug,
              titulo: item.titulo,
              descricao: item.descricao,
              ordem: item.ordem.toString(),
              currentActive: true
            });
            handleToastMessage('ativado', 'Formulário');
            handleGetFlow();
            getProcessingApi();
            handleEmptyData();
            break;
          // @ts-ignore
          case 'process':
            await handleOptionUpdateProcess({
              process_id: item.id,
              ordem: item.ordem,
              atividade: item.atividade,
              orgao: item.orgao,
              currentActive: true,
              setor: item.setor,
              status: item.status
            });
            handleToastMessage('ativado', 'Tramite');
            handleGetFlow();
            getProcessingApi();
            handleEmptyData();
            break;
          case 'document':
            await handleDocumentUpdateProcess({
              flow_id: item.flow,
              document_id: item.id,
              active: true,
            });
            handleToastMessage('ativado', 'Documento');
            await handleGetFlow();
            await getProcessingApi();
            await getDocuments();
            handleEmptyData();
            break;
          default:
            break;
        }
      }
    } catch (error) {
      setLoadingCancel(false);
      if (error?.response?.data?.message) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'error-alt',
            title: 'Error ao desativar',
            message: error?.response?.data?.message,
          },
        });
      }
      console.error(error?.response?.data?.message);
    } finally {
      setLoadingCancel(false);
    }
  };

  const handleShowModalInformation = (data: getFluxoProps | getFormsProps) => {
    setShowModalInformation(true);
    setDataSelected(data);
  };

  const handleDeleteWorkflow = (workflowDelete: any) => {
    setWorkflowSelected(workflowDelete);
    setShowModalDeleteWorkflow(true);
  };

  const handleDetailsWorkflow = (workflowData: any) => {
    setWorkflowSelected(workflowData);
    setShowModalDetailWorkflow(true);
  };

  useEffect(() => {
    getProcessingApi();
    handleGetFlow();
    getTerms();
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow_slug]);



  useEffect(() => {
    if (flowInformationData?.slug && flowInformationData.tipo !== "Manual") {
      getWorkflow(flowInformationData.slug);
    }
  }, [flowInformationData]);

  return (
    <>
      <Container style={{ marginTop: 56 }}>
        {loading && (
          <div className="d-flex mt-4 justify-content-center">
            <Loading />
          </div>
        )}
        {flowInformationData && (
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdInfo size={20} />
                </CardIcon>
                <Title>Informações do Fluxo</Title>
              </Box>
              <Box>
                <Button
                  variant="primary"
                  onClick={() =>
                    handleShowModalInformation(flowInformationData)
                  }
                >
                  <MdInfo size={20} /> Detalhes
                </Button>
              </Box>
            </CardHeader>
            <Card.Body>
              <Row>
                <Col sm={4}>
                  <InfoTitle>Título</InfoTitle>
                  <InformationDescription>
                    {flowInformationData.titulo}
                  </InformationDescription>

                  <InfoTitle>Slug</InfoTitle>
                  <InformationDescription>
                    {flowInformationData.slug}
                  </InformationDescription>

                  <InfoTitle>Adicionado em</InfoTitle>
                  <InformationDescription>
                    {dateFormatToBR(flowInformationData.created_at, true)}
                  </InformationDescription>
                </Col>
                <Col sm={4}>
                  {flowInformationData?.servico_slug && (
                    <>
                      <InfoTitle>Identificador do serviço</InfoTitle>
                      <InformationDescription>
                        {serviceIdentify}
                      </InformationDescription>
                    </>
                  )}

                  <InfoTitle>Ativo</InfoTitle>
                  <InformationDescription>
                    <Status type={flowInformationData.ativo.toString()} />
                  </InformationDescription>

                  <InfoTitle>Atualizado em </InfoTitle>
                  <InformationDescription>
                    {dateFormatToBR(flowInformationData.updated_at, true)}
                  </InformationDescription>
                  <InfoTitle>Órgão</InfoTitle>
                  <InformationDescription>
                    {flowInformationData?.orgao_nome || 'Sem resultado.'}
                  </InformationDescription>
                </Col>
                <Col sm={4}>
                  <InfoTitle>Modelo</InfoTitle>
                  <InformationDescription>
                    <Status type={flowInformationData?.modelo.toString()} />
                  </InformationDescription>

                  <InfoTitle>Resposta Automática</InfoTitle>
                  <InformationDescription>
                    <Status type={flowInformationData?.automatico.toString()} />
                  </InformationDescription>
                  <InfoTitle>Tipo fluxo</InfoTitle>
                  <InformationDescription>
                    {flowInformationData?.tipo}
                  </InformationDescription>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
        {!loading && !flowInformationData && (
          <h1 style={{ textAlign: "center" }}>Nenhum fluxo encontrado!</h1>
        )}
      </Container>

      {!(loading || loadingProcess) && (
        <Container style={{ marginTop: 56, marginBottom: 20 }}>
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdAssignment size={20} />
                </CardIcon>
                <Title>Informações</Title>
              </Box>
            </CardHeader>
            <Card.Body>
              <Box
                style={{
                  borderBottom: "1px solid #E1E6EF",
                  borderColor: "divider",
                }}
              >
                <Tabs
                  classes={{ indicator: classes.indicatorTab }}
                  className={classes.tabs}
                  value={valueTab}
                  onChange={(event, value) => setValueTab(value)}
                  aria-label="basic tabs example"
                >
                  <Tab
                    classes={{
                      selected: classes.tabSelected,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Formulários"
                    {...a11yProps(0)}
                    value={0}
                  />
                  {((flowInformationData?.tipo !== "Automático") && !flowInformationData?.automatico) && <Tab
                    classes={{
                      selected: classes.tabSelected,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Tramitação interna"
                    {...a11yProps(1)}
                    value={1}
                  />}
                  <Tab
                    classes={{
                      selected: classes.tabSelected,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Documentos"
                    {...a11yProps(2)}
                    value={2}
                  />
                  <Tab
                    classes={{
                      selected: classes.tabSelected,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Termos de serviço"
                    {...a11yProps(3)}
                    value={3}
                  />
                  {flowInformationData?.tipo !== "Manual" && <Tab
                    classes={{
                      selected: classes.tabSelected,
                      labelIcon: classes.labelIcon,
                    }}
                    label="Fluxo de integração"
                    {...a11yProps(4)}
                    value={4}
                  />}

                </Tabs>


              </Box>
              {valueTab === 0 && (
                <>
                  <ContainerButton>
                    <SelectFilter
                      onChange={(event) => {
                        setFilterBy(event.target.value);
                      }}
                    >
                      <option value={"all"} selected={filterBy === "all"}>
                        {filterBy !== "all" ? "Todos" : "Filtrar por"}
                      </option>
                      <option value="true" selected={filterBy === "true"}>
                        Ativos
                      </option>
                      <option value="false" selected={filterBy === "false"}>
                        Inativos
                      </option>
                    </SelectFilter>
                    <Button
                      variant="primary"
                      onClick={() =>
                        history.push(`/fluxo/${flow_slug}/cadastrar-formulario`)
                      }
                    >
                      <MdAddCircle size={20} /> Formulário
                    </Button>
                  </ContainerButton>
                  <CustomTable>
                    <thead className="table-header">
                      <tr>
                        <th>ordem</th>
                        <th>título</th>
                        <th>ativo</th>
                        <th>Criado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {flowInformationData?.formularios &&
                        flowInformationData.formularios
                          .filter((form) => {
                            if (filterBy === "true") {
                              return form.ativo === true;
                            }
                            if (filterBy === "false") {
                              return form.ativo === false;
                            }
                            return form;
                          })
                          .map((form) => (
                            <tr key={form.slug}>
                              <td>{form.ordem}</td>
                              <td>{form.titulo}</td>
                              <td>
                                <Status type={form.ativo.toString()} />
                              </td>
                              <td>{dateFormatToBR(form.created_at, true)}</td>
                              <td>
                                <DropdownCustom>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="drop-button"
                                  >
                                    <MdMoreVert size={20} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <DropdownItemCustom
                                      onClick={() =>
                                        history.push(
                                          `/fluxo/formulario/${form.slug}/${flow_slug}/construir`
                                        )
                                      }
                                    >
                                      <MdBuild className="icon-action" />
                                      Construir
                                    </DropdownItemCustom>
                                    <DropdownItemCustom
                                      onClick={() =>
                                        history.push(
                                          `/fluxo/${flow_slug}/form/${form.slug}/editar`
                                        )
                                      }
                                    >
                                      <Edit className="icon-action" />
                                      Gerenciar
                                    </DropdownItemCustom>
                                    <DropdownItemCustom
                                      onClick={() => {
                                        handleModalCancel();
                                        setFormDataSelected(form);
                                        setDescriptionCancel(
                                          form.ativo ?
                                            'Deseja desativar esse formulário?' :
                                            'Deseja ativar esse formulário?'
                                        );
                                        setCurrentFlowActive(
                                          form.ativo ?
                                            'error' :
                                            'success'
                                        )
                                        setCurrentTypeCancel('forms');
                                      }}
                                    >
                                      {form.ativo ? (
                                        <>
                                          <IoMdCloseCircle className="icon-action" />
                                          Desativar
                                        </>
                                      ) : (
                                        <>
                                          <MdCheckCircle className="icon-action" />
                                          Ativar
                                        </>
                                      )}
                                    </DropdownItemCustom>
                                  </Dropdown.Menu>
                                </DropdownCustom>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </CustomTable>
                </>
              )}
              {valueTab === 1 && (
                <>
                  <ContainerButton>
                    <SelectFilter
                      onChange={(event) => {
                        setTramFilterBy(event.target.value);
                      }}
                    >
                      <option value={"all"} selected={filterTramBy === "all"}>
                        {filterTramBy !== "all" ? "Todos" : "Filtrar por"}
                      </option>
                      <option value="true" selected={filterTramBy === "true"}>
                        Ativos
                      </option>
                      <option value="false" selected={filterTramBy === "false"}>
                        Inativos
                      </option>
                    </SelectFilter>
                    <Button
                      variant="primary"
                      style={{ margin: "0px 10px" }}
                      onClick={() =>
                        history.push(`/fluxo/${flow_slug}/tramitacao/construir`)
                      }
                    >
                      <MdAddCircle size={20} /> Tramitação
                    </Button>
                  </ContainerButton>
                  <CustomTable>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Atividade</th>
                        <th>Status</th>
                        <th>Órgão</th>
                        <th>ativo</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {processing.length >= 0 &&
                        processing
                          .filter((pross) => {
                            if (filterTramBy === "true") {
                              return pross.ativo === true;
                            }
                            if (filterTramBy === "false") {
                              return pross.ativo === false;
                            }
                            return pross;
                          })
                          .map((process) => (
                            <tr>
                              <td>{process.ordem}</td>
                              <td>{process.atividade}</td>
                              <td>{process.status}</td>
                              <td>{process.orgao_sigla}</td>
                              <td>
                                <Status type={process.ativo.toString()} />
                              </td>
                              <td>
                                <DropdownCustom>
                                  <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="drop-button"
                                  >
                                    <MdMoreVert size={20} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <DropdownItemCustom
                                      onClick={() =>
                                        history.push(
                                          `/fluxo/${flow_slug}/tramitacao/gerenciar`,
                                          { process }
                                        )
                                      }
                                    >
                                      <MdSettings className="icon-action" />
                                      Gerenciar
                                    </DropdownItemCustom>
                                    <DropdownItemCustom
                                      onClick={() => {
                                        handleModalCancel();
                                        setFormDataSelected(process);
                                        setDescriptionCancel(
                                          process.ativo ?
                                            'Deseja desativar essa tramitação?' :
                                            'Deseja ativar essa tramitação?'
                                        );
                                        setCurrentFlowActive(
                                          process.ativo ?
                                            'error' :
                                            'success'
                                        )
                                        setCurrentTypeCancel('process');
                                      }}
                                    >
                                      {process.ativo ? (
                                        <>
                                          <IoMdCloseCircle className="icon-action" />
                                          Desativar
                                        </>
                                      ) : (
                                        <>
                                          <MdCheckCircle className="icon-action" />
                                          Ativar
                                        </>
                                      )}
                                    </DropdownItemCustom>
                                  </Dropdown.Menu>
                                </DropdownCustom>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </CustomTable>
                </>
              )}
              {valueTab === 2 && (
                <>
                  <ContainerButton>
                    <div />
                    <Button
                      variant="primary"
                      onClick={() =>
                        history.push(
                          `/fluxo/${flow_slug}/documento/orgao/${flowInformationData.orgao}/configurar`
                        )
                      }
                    >
                      <MdAddCircle size={20} /> Criar documento
                    </Button>
                  </ContainerButton>
                  <CustomTable>
                    <thead className="table-header">
                      <tr>
                        <th>Assunto</th>
                        <th>Criado por</th>
                        <th>Ativo</th>
                        <th>Tipo</th>
                        <th>Criado em</th>
                        <th>Atualizado em</th>
                        <th>ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {documents?.length >= 0 &&
                        documents.map((document) => (
                          <tr>
                            <td>{document.assunto}</td>
                            <td>
                              {document?.user?.first_name
                                ? document?.user?.first_name
                                : "Usuário"}
                            </td>
                            <td><Status type={document?.ativo?.toString() || "false"} /></td>
                            <td>{document.tipo}</td>
                            <td>{dateFormatToBR(document.created_at, true)}</td>
                            <td>{dateFormatToBR(document.updated_at, true)}</td>
                            <td>
                              <DropdownCustom>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="drop-button"
                                >
                                  <MdMoreVert size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <DropdownItemCustom
                                    onClick={() =>
                                      history.push(
                                        `/fluxo/${flow_slug}/documento/orgao/${flowInformationData.orgao}/configurar/${document.id}`
                                      )
                                    }
                                  >
                                    <MdSettings className="icon-action" />
                                    Gerenciar
                                  </DropdownItemCustom>
                                  <DropdownItemCustom
                                    onClick={() => {
                                      handleModalCancel();
                                      setFormDataSelected(document);
                                      setDescriptionCancel(
                                        document.ativo ?
                                          'Deseja desativar esse documento?' :
                                          'Deseja ativar esse documento?'
                                      );
                                      setCurrentFlowActive(
                                        document.ativo ?
                                          'error' :
                                          'success'
                                      )
                                      setCurrentTypeCancel('document');
                                    }}
                                  >
                                    {document.ativo ? (
                                      <>
                                        <IoMdCloseCircle className="icon-action" />
                                        Desativar
                                      </>
                                    ) : (
                                      <>
                                        <MdCheckCircle className="icon-action" />
                                        Ativar
                                      </>
                                    )}
                                  </DropdownItemCustom>
                                </Dropdown.Menu>
                              </DropdownCustom>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </CustomTable>
                </>
              )}
              {valueTab === 3 && (
                <>
                  <ContainerButton>
                    <div />
                    <Button
                      variant="primary"
                      onClick={() =>
                        history.push(
                          `/fluxo/${flow_slug}/termos/novo`
                        )
                      }
                    >
                      <MdAddCircle size={20} /> Criar Termo
                    </Button>
                  </ContainerButton>
                  <CustomTable>
                    <thead className="table-header">
                      <tr>
                        <th>Título</th>
                        <th>Versão</th>
                        <th>Ativo</th>
                        <th>Criado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {terms?.results?.length >= 0 &&
                        terms?.results?.map((term) => (
                          <tr>
                            <td>{term.titulo}</td>
                            <td>
                              {term?.versao}
                            </td>
                            <td><Status type={term?.ativo?.toString() || "false"} /></td>
                            <td>{dateFormatToBR(term?.created_at, true)}</td>
                            <td>
                              <DropdownCustom>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="drop-button"
                                >
                                  <MdMoreVert size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <DropdownItemCustom
                                    onClick={() => {
                                      history.push(
                                        `/fluxo/${flow_slug}/termos/novo/${term.id}`
                                      )
                                    }}
                                  >
                                    <FaCopy className="icon-action" />
                                    Copiar Termo
                                  </DropdownItemCustom>
                                  <DropdownItemCustom
                                    onClick={() => {
                                      setTermSelected(term);
                                      setShowTermModal(true);
                                    }}
                                  >
                                    <FaEye className="icon-action" />
                                    Visualizar
                                  </DropdownItemCustom>
                                </Dropdown.Menu>
                              </DropdownCustom>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot className="table-foot">
                      {terms?.total_pages > 1 ? (
                        <Paginate
                          flowData={terms}
                          setPage={getTerms}
                          page={terms?.current}
                        />
                      ) : undefined}
                    </tfoot>
                  </CustomTable>
                </>
              )}
              {valueTab === 4 && (
                <>
                  <ContainerButton>
                    <div />
                    {!loadingWorkflow && workflow?.length === 0 && (<Button
                      variant="primary"
                      onClick={() =>
                        history.push(
                          `/fluxo/${flow_slug}/workflow/salvar`
                        )
                      }
                    >
                      <MdAddCircle size={20} /> Adicionar Integração
                    </Button>)}

                  </ContainerButton>
                  <CustomTable>
                    <thead className="table-header">
                      <tr>
                        <th>Título</th>
                        <th>Descrição</th>
                        <th>Criando em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {workflow?.length >= 0 &&
                        workflow?.map((work) => (
                          <tr>
                            <td>{work.titulo}</td>
                            <td>
                              {work?.descricao}
                            </td>

                            <td>{dateFormatToBR(work?.created_at, true)}</td>
                            <td>
                              <DropdownCustom>
                                <Dropdown.Toggle
                                  id="dropdown-basic"
                                  className="drop-button"
                                >
                                  <MdMoreVert size={20} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <DropdownItemCustom DropdownItemCustom
                                    onClick={() => {
                                      handleDetailsWorkflow(work);
                                    }}
                                  >
                                    <FaEye className="icon-action" />
                                    Detalhes
                                  </DropdownItemCustom>
                                  <DropdownItemCustom DropdownItemCustom
                                    onClick={() => {
                                      history.push(
                                        `/fluxo/${flow_slug}/workflow/salvar`
                                      )
                                    }}
                                  >
                                    <FaEdit className="icon-action" />
                                    Editar
                                  </DropdownItemCustom>
                                  <DropdownItemCustom
                                    onClick={handleDeleteWorkflow.bind(this, work)}
                                  >
                                    <FaTrash className="icon-action" />
                                    Remover
                                  </DropdownItemCustom>
                                </Dropdown.Menu>
                              </DropdownCustom>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </CustomTable>
                </>
              )}
            </Card.Body>
            <CardFooterCustom>
              <ButtonReturn onClick={() => history.push("/fluxo")}>
                Voltar
              </ButtonReturn>
            </CardFooterCustom>
          </Card>
        </Container >
      )}

      {
        dataSelected && (
          <InformationModal
            isActive={showModalInformation}
            handleActive={handleCloseModal}
            data={dataSelected}
          />
        )
      }
      {
        workflowSelected && (
          <InformationWorkflowModal
            isActive={showModalDetailWorkflow}
            handleActive={handleCloseModalDetailsWorkflow}
            data={workflowSelected}
          />
        )
      }

      {
        termSelected && (
          <TermModal
            isActive={showTermModal}
            handleActive={handleCloseTermModal}
            data={termSelected}
          />
        )
      }
      {
        descriptionCancel && showModalCancel && currentTypeCancel && (
          <CancelModal
            handleShow={handleModalCancel}
            show={showModalCancel}
            description={descriptionCancel}
            title={allDeleteActionTitles[currentTypeCancel].title}
            handleActionOk={() => handleActiveItem(formDataSelected, currentTypeCancel)}
            loading={loadingCancel}
            type={currentFlowActive}
          />
        )
      }
      {flow_slug && workflowSelected && (<DeleteModal show={showModalDeleteWorkflow} handleShow={setShowModalDeleteWorkflow} flow_slug={flow_slug} workflow={workflowSelected} />)}
    </>
  );
};

export default Information;
