import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MdPassword } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { DocumentProps } from '../..';
import { useStateValue } from '../../../../providers/StateProvider';
import { requestCodeSign, sendCodeSignToValidate } from '../../../../services/signature';
import { actionTypes } from '../../../../store/reducer';
import { ButtonCancel, ButtonSave, CardFooterCustom, CardIcon } from '../../styles';
import { BodyModal, ButtonSendCode, DescriptionModal, InputCode, MessageError, MessageSuccess, SendCodeMessage, TitleModal } from './styles';
import Loading from '../../../../Components/Loading';

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    documents: DocumentProps;
};

interface RequestCode {
    message: string;
    state: string;
}

const ModalCodeValidate = ({ show, setShow, documents }: Props): JSX.Element => {
    const history = useHistory();
    const [code, setCode] = useState<string>('');
    const handleClose = () => setShow(false);
    const [errorInput, setErrorInput] = useState<boolean>(false);
    const [successInput, setSuccessInput] = useState<boolean>(false);
    const [, dispatch] = useStateValue();
    const [requestCode, setRequestCode] = useState<RequestCode>();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [, setCodingValidation] = useState();

    const handleAssignCode = (e) => {
        const value: string = e.target.value.replace(/\D/g, '');
        setCode(value);
        if (value.length !== 6) {
            setErrorInput(true);
            setSuccessInput(false);
        } else {
            setErrorInput(false);
            setSuccessInput(true);
        }
    }

    const handleSave = () => {
        history.goBack();
        dispatch({
            type: actionTypes.SET_FLASH_MESSAGE,
            flashMessage: {
                show: true,
                type: 'success-alt',
                title: 'Sucesso',
                message: 'Documento criado!',
            },
        });
    }

    const validateCode = async () => {
        setLoading(true);
        try {
            const token: string | null = localStorage.getItem('gov_access_token');
            const { data } = await sendCodeSignToValidate({
                code,
                state: requestCode.state,
                identificador: documents.id,
                tipo: 'Documento',
                internal_state: requestCode.state,
                client_id: (window as any)._env_.REACT_APP_FORMS_CLIENT_ID,
                token
            });
            setCodingValidation(data);
            handleSave();
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const handleRequestCode = async () => {
        const dataStorage = localStorage.getItem("gov_user_data");
        const userData = JSON.parse(dataStorage);

        const token: string | null = localStorage.getItem('gov_access_token');

        const { data } = await requestCodeSign(userData.cpf, userData.contato.email, token);
        setRequestCode(data);
    }

    useEffect(() => {
        const dataStorage = localStorage.getItem("gov_user_data");
        const userData = JSON.parse(dataStorage);

        setEmail(userData?.contato?.email);
    }, []);

    useEffect(() => {
        if (documents) {
            handleRequestCode();
        }
    }, [documents]);

    return (
        <>
            <Modal size='lg' show={show} centered>
                <Modal.Body>
                    <BodyModal>
                        <CardIcon>
                            <MdPassword />
                        </CardIcon>
                        <TitleModal>Assinatura eletrônica</TitleModal>
                        <DescriptionModal>Assine a tramitação com o código enviado para seu email: <strong>{email ?? ''}</strong></DescriptionModal>
                        {loading ? (
                            <Loading />
                        ) : (
                            <InputCode placeholder='000000' maxLength={6} value={code}
                                onChange={handleAssignCode}
                                error={errorInput}
                                success={successInput}
                            />
                        )}
                        {errorInput && <MessageError>Error</MessageError>}

                        {successInput && <MessageSuccess>Verificado</MessageSuccess>}
                        <SendCodeMessage>Não recebeu o código? <ButtonSendCode onClick={handleRequestCode}>Reenviar</ButtonSendCode></SendCodeMessage>

                    </BodyModal>
                </Modal.Body>
                <CardFooterCustom>
                    <ButtonCancel onClick={handleClose}>
                        Cancelar
                    </ButtonCancel>
                    <ButtonSave onClick={validateCode} disabled={!successInput}>
                        Finalizar
                    </ButtonSave>
                </CardFooterCustom>
            </Modal>
        </>
    );
};

export default ModalCodeValidate;