import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const apisso = axios.create({
  baseURL: (window as any)._env_.REACT_APP_API_SSO ?? '',
});

const ssoSecondary = axios.create({
  baseURL: (window as any)._env_.REACT_APP_SSO_MTI ?? '',
});

const apisiseci = axios.create({
  baseURL: (window as any)._env_.REACT_APP_API_SISECI ?? '',
});

const apinewforms = axios.create({
  baseURL: (window as any)._env_.REACT_APP_API_NEWFORMS ?? '',
});

apisiseci.defaults.headers.common.Authorization = (window as any)._env_.REACT_APP_API_SISECI_KEY ?? '';
apisso.defaults.headers.common.Authorization = (window as any)._env_.REACT_APP_API_SSO_KEY ?? '';
apinewforms.defaults.headers.common.Authorization = (window as any)._env_.REACT_APP_API_FORMS_KEY ?? '';


export { apisso, apisiseci, ssoSecondary, apinewforms };
