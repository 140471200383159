import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => (required ? 'inline' : 'none')};
  }
`;

export const ButtonHeader = styled(Button)`
  margin: 10px 0px;
`;
