import { AxiosResponse } from 'axios';
import { apinewforms } from './api';

export const createRequestDocument = (
  solicitacao: number,
  assunto: string,
  conteudo: string,
  cabecalho: string
): Promise<AxiosResponse> =>
  apinewforms.post('flow/documentos-resposta/', {
    solicitacao,
    assunto,
    conteudo,
    cabecalho,
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const getResponseVisualization = (
  id_solicitacao: string
): Promise<AxiosResponse> =>
  apinewforms.get(
    `flow/documentos-resposta/?identificador_solicitacao=${id_solicitacao}`
    , {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
      },
    });

export const getDocumentList = (flow_id: string): Promise<AxiosResponse> =>
  apinewforms.get(`flow/formato-documento/?flow_id=${flow_id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const getVisualizationResponse = (id_document: string, id_solicitacao: string): Promise<AxiosResponse> =>
  apinewforms.get(`flow/documentos-resposta/${id_document}/?identificador_solicitacao=${id_solicitacao}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const updateRequestDocument = (
  solicitacao: number,
  assunto: string,
  conteudo: string,
  cabecalho: string,
  id_solicitacao: string,
  document_id: string,
): Promise<AxiosResponse> =>
  apinewforms.put(`flow/documentos-resposta/${document_id}/?identificador_solicitacao=${id_solicitacao}`, {
    id: document_id,
    solicitacao,
    assunto,
    conteudo,
    cabecalho,
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });