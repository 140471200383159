import styled from "styled-components";

import { Card as BCard, Col, Container as BContainer, Row, Table } from "react-bootstrap";
import { MdAccountTree, MdDashboard, MdPostAdd } from "react-icons/md";

export const Container = styled(BContainer)``;

export const Header = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const Greetings = styled.h1`
  color: #1E212A;
  text-transform: capitalize;
  font-family: 'Roboto';
  font-size: 28px;
`;

export const GreetingsTwo = styled.h1`
  color: #506176;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: normal;
`;

export const Content = styled(Row)``;

export const ContentLeft = styled(Col)``;

export const ContentRight = styled(Col)``;

export const Card = styled(BCard)`
  margin-bottom: 20px;
`;

export const BoxHeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
`;

export const TitleHeadCard = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;


export const TitleCountHeadCard = styled.div`
  display: flex;
  border-left: 1px solid #E1E6EF;
  height: 100%;
  align-items: center;
  width: 80px;
  color: #004F9F;
  font-size: 28px;
  font-weight: 700;
  justify-content: center;
`;

export const BoxTextsTitleCard = styled.div`
  padding-left: 20px;
`;

export const TextsTitleCard = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #1E212A;
`;

export const TextsSubTitleCard = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #506176;
`;

export const AccountTreeIcon = styled(MdAccountTree)`
  color: #004F9F;
  background-color: rgba(14, 134, 254, 0.08);;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  padding: 15px;
`;

export const DashboardIcon = styled(MdDashboard)`
  color: #004F9F;
  background-color: rgba(14, 134, 254, 0.08);;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  padding: 15px;
`;

export const PostAddIcon = styled(MdPostAdd)`
  color: #004F9F;
  background-color: rgba(14, 134, 254, 0.08);;
  border-radius: 8px;
  width: 56px;
  height: 56px;
  padding: 15px;
`;

export const BoxChart = styled.div`
  border-top: 1px solid #E1E6EF;
  padding-top: 20px;
`;

export const BoxServices = styled.div`
  border-top: 1px solid #E1E6EF;
  overflow: auto !important;
  height: 558px;
`;

export const TableSevices = styled(Table).attrs({

  striped: true,
})`

.table-header {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }

  .table-body td {
    padding: 30px 30px;
    text-transform: uppercase;
  }

  .table-body {
    border-top: none !important;
  }

  .table-body tr:nth-of-type(odd) {
    background-color:  rgba(14, 134, 254, 0.05);
    color: #004F9F;

  }

`;
export const Tr = styled.tr`
  height: 100px;
`;

export const TdText = styled.td`
  text-transform: capitalize;
  vertical-align: middle;


`;

export const TdNumber = styled.td`
  vertical-align: middle;
  color: #004F9F !important;
  font-size: 16px;
  font-weight: 700;
`;