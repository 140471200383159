// eslint-disable-next-line import/no-anonymous-default-export
export default {
  colors: {
    primary: '#004F9F',
    secundary: '#004F9F',
    background_icon: 'rgba(14, 134, 254, 0.08)',
    shape: '#FFFFFF',
    text: '#1E212A',
    text_secundary: '#506176',
    success: '#27AB6E',
    error: '#EC4C47',
    background: '#F3F5FB',
    background_icon_error: 'rgba(236, 76, 71, 0.08)'
  }
}