import React, { useEffect, useState } from "react";

import { Card, Col, Row, Tabs, Tab } from "react-bootstrap";
import { MdCleaningServices, MdOutlinePostAdd } from "react-icons/md";
import { getOrganDataList } from "../../services/organ";
import { cpfMask } from "../../utils/cpfFormat";
import RequestsList from "./Components/RequestsList";

import {
  Title,
  ContainerMain,
  CardHeader,
  Box,
  CardIcon,
  SelectFilter,
  FormFilter,
  InputSearch,
  InputDateInicial,
  InputDateFinal,
  BoxSearch,
  IconSearch,
  ButtonSearch,
  ButtonClearSearch,
  OptionFilter,
} from "./styles";
import RequestsWorkflowList from "./Components/RequestsWorkflowList";

var today = new Date().toISOString();

const Requests = (): JSX.Element => {
  const [searchData, setSearchData] = useState<string>("");
  const [isSearch, setIsSearch] = useState(false);
  const [filter, setFilter] = useState<string>("select");
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [countOrgaos, setCountOrgaos] = useState(0);

  const formatDate = (payload: string) => {
    const y = payload.split("-")[0];
    const m = payload.split("-")[1];
    const d = payload.split("-")[2].split("T")[0];

    return `${y}-${m}-${d}`;
  };

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let value = e.target.value;
    if (filter === "cpf" && value.length) {
      setSearchData(cpfMask(value));
    } else if (filter === "fluxo" && value.length) {
      setSearchData(value);
    } else if (filter === "servico" && value.length) {
      setSearchData(value);
    } else if (filter === "orgao" && value.length) {
      setSearchData(value);
    } else {
      setSearchData(value.replace(/\D/g, ""));
    }
  };

  const handleChangeDataInicial = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = e.target.value;
    setDataInicial(value);
  };

  const handleChangeDataFinal = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    let value = e.target.value;
    setDataFinal(value);
  };

  const getCountOrgaos = async () => {
    const tokenSSO: string | null = localStorage.getItem("gov_access_token");
    const data: any = await getOrganDataList(tokenSSO);
    setCountOrgaos(data.data.length);
  };

  useEffect(() => {
    if (filter === "cpf" && searchData.length) {
      setSearchData(cpfMask(searchData));
    } else if (filter === "fluxo" && searchData.length) {
      setSearchData(searchData);
    } else if (filter === "servico" && searchData.length) {
      setSearchData(searchData);
    } else if (
      filter === "data" &&
      dataInicial !== formatDate(today) &&
      dataFinal !== formatDate(today)
    ) {
      setDataInicial(dataInicial);
      setDataFinal(dataFinal);
    } else if (filter === "orgao" && searchData.length) {
      setSearchData(searchData);
    } else {
      setSearchData(searchData.replace(/\D/g, ""));
    }

    if (countOrgaos === 0) {
      getCountOrgaos();
    }

    setDataInicial(formatDate(today));
    setDataFinal(formatDate(today));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleResetInputSearch = () => {
    setFilter("select");
    setSearchData("");
    setDataInicial(formatDate(today));
    setDataFinal(formatDate(today));
    setIsSearch(false);
  };

  return (
    <ContainerMain>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdOutlinePostAdd size={20} />
                </CardIcon>
                <Title>Solicitações</Title>
              </Box>
              <FormFilter>
                <SelectFilter
                  onChange={(event) => setFilter(event.target.value)}
                >
                  <OptionFilter value="select" selected={filter === "selected"}>
                    Filtrar por
                  </OptionFilter>
                  <OptionFilter value="cpf" selected={filter === "cpf"}>
                    CPF
                  </OptionFilter>
                  <OptionFilter
                    value="protocolo"
                    selected={filter === "protocolo"}
                  >
                    Protocolo
                  </OptionFilter>
                  <OptionFilter value="fluxo" selected={filter === "fluxo"}>
                    Fluxo
                  </OptionFilter>
                  <OptionFilter value="servico" selected={filter === "servico"}>
                    Serviço
                  </OptionFilter>
                  {countOrgaos > 1 && (
                    <OptionFilter value="orgao" selected={filter === "orgao"}>
                      Órgão
                    </OptionFilter>
                  )}
                  <OptionFilter value="data" selected={filter === "data"}>
                    Data
                  </OptionFilter>
                </SelectFilter>
                <BoxSearch>
                  {filter === "data" ? (
                    <>
                      <InputDateInicial
                        style={{ width: "auto" }}
                        type={"date"}
                        placeholder="Data inicial"
                        value={dataInicial}
                        disabled={false}
                        onChange={handleChangeDataInicial}
                      />

                      <InputDateFinal
                        style={{ width: "auto" }}
                        type={"date"}
                        placeholder="Data final"
                        value={dataFinal}
                        disabled={false}
                        onChange={handleChangeDataFinal}
                      />
                    </>
                  ) : (
                    <>
                      <IconSearch />
                      <InputSearch
                        style={{ width: "auto" }}
                        type={"text"}
                        placeholder="Digite sua busca"
                        value={searchData}
                        disabled={filter === "select"}
                        onChange={handleChangeSearch}
                      />
                    </>
                  )}
                </BoxSearch>
                {!isSearch ? (
                  <ButtonSearch
                    disabled={false}
                    onClick={() => {
                      setIsSearch(true);
                    }}
                  >
                    {" "}
                    Buscar
                  </ButtonSearch>
                ) : (
                  <ButtonClearSearch
                    className="text-light"
                    variant="info"
                    onClick={handleResetInputSearch}
                  >
                    <MdCleaningServices size={15} /> Limpar
                  </ButtonClearSearch>
                )}
              </FormFilter>
            </CardHeader>
            <Card.Body>
              <Tabs
                defaultActiveKey="waiting"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="waiting" title="Solicitado">
                  <RequestsList
                    status="Solicitado"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="profile" title="Em andamento">
                  <RequestsList
                    status="Em Andamento"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="Encaminhado" title="Encaminhado">
                  <RequestsList
                    status="Encaminhado"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData.replace(/\D/g, "")}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="contact" title="Finalizado">
                  <RequestsList
                    status="Finalizado"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="Canceled" title="Cancelados">
                  <RequestsList
                    status="Cancelado"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="Restituid" title="Restituídos">
                  <RequestsList
                    status="Restituído"
                    isSearch={isSearch}
                    filter={filter}
                    search={searchData.replace(/\D/g, "")}
                    dataInicial={dataInicial}
                    dataFinal={dataFinal}
                  />
                </Tab>
                <Tab eventKey="Workflow" title="Workflows executados">
                  <RequestsWorkflowList />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </ContainerMain>
  );
};

export default Requests;
