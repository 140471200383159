import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { MdDescription } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { getDocumentById, putDocument } from '../../services/requests';

import {
  ContainerMain,
  CardHeader,
  Box,
  CardIcon,
  Title,
  CardFooterCustom,
  ButtonCancel,
  ButtonSave,
  ButtonReturn
} from './styles';
import MyEditor from '../../Components/MyEditor';
import dateFormat from '../../utils/dateFormat';
import { cpfMask } from '../../utils/cpfFormat';

const DocVisualization = (): JSX.Element => {
  const { id, identificador } = useParams<{ id: string, identificador: string }>();
  const history = useHistory();
  const [doc, setDoc] = useState(null);

  const getAllApis = async () => {
    const [documentapi]: any = await Promise.all([
      getDocumentById(id, identificador)
    ]);
    setDoc(documentapi.data);
  }
  const backToSolicitation = () => {
    if (doc?.solicitacao?.identificador) {
      history.push(`/solicitacoes/detalhes/${doc?.solicitacao?.identificador}`);
    } else {
      history.goBack();
    }
  }

  const updateConformidate = async (conformidade: boolean) => {
    try {
      await putDocument(id, conformidade, identificador);
    } catch (err) {
      console.error(err);
    }
    backToSolicitation();
  }

  useEffect(() => {
    getAllApis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQRCodeImageURL = (value) => {
    return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(value)}&size=80x80`;
  }

  const addInfoSign = () => {
    const qrCodeValue = `${(window as any)._env_.REACT_APP_URL_CONSULTAR_DOC}/${doc?.solicitacao?.hash}/${doc?.solicitacao?.identificador}`;
    const qrCodeImgURL = getQRCodeImageURL(qrCodeValue);

    return `
        <br/>
       <div style="text-align: left; background: #F3F4F7; position: fixed; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px; display: flex;  align-items: center;">
          <div>
            Assinado eletronicamente por: ${cpfMask(doc?.solicitacao?.user_data?.cpf) || ''} • ${doc?.solicitacao?.user_data?.nome || ''} em ${doc?.updated_at && dateFormat(doc?.updated_at, true)}
            <br/>
            Hash de verificação:  ${doc?.solicitacao?.hash || ''}
            <br/>
            Identificação:  ${doc?.solicitacao?.identificador || ''}
            <br/>
            “Essa assinatura tem respaldo legal de acordo com a lei federal n° 14.063/2020”<br />
            Consulte a autenticidade do documento em: ${(window as any)._env_.REACT_APP_URL_CONSULTAR_DOC}/
          </div>
           <img src="${qrCodeImgURL}" alt="QRCode" style="image-rendering: crisp-edges; -ms-interpolation-mode: nearest-neighbor; margin-left: 10px" />
        </div>`;
  }

  return (
    <ContainerMain>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdDescription size={20} />
                </CardIcon>
                <Title>{doc?.documento_origem?.assunto}</Title>
              </Box>
              <Button onClick={backToSolicitation}>Voltar</Button>
            </CardHeader>
            <Card.Body>
              {doc && (
                <MyEditor
                  value={`
                    ${doc?.conteudo}
                    ${addInfoSign()}`}
                  print={true}
                />
              )}
            </Card.Body>
            <CardFooterCustom>
              {doc && !doc.verificado_em ? (
                <>
                  <ButtonCancel onClick={() => { updateConformidate(false) }}>
                    Recusar
                  </ButtonCancel>
                  <ButtonSave
                    onClick={() => { updateConformidate(true) }}
                  >
                    Aceitar
                  </ButtonSave>
                </>
              ) : (
                <ButtonReturn onClick={() => { history.goBack() }}>Voltar</ButtonReturn>
              )}
            </CardFooterCustom>
          </Card>
        </Col>
      </Row>
    </ContainerMain>
  );
}

export default DocVisualization;