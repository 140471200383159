/* eslint-disable no-useless-concat */
import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { sendFile } from '../../services/files';

type SelectorProps = Array<{
    ordem: number;
    slug: string;
    titulo: string;
    campos: Array<{
        id: number;
        ordem: number;
        tipo: string;
        titulo: string;
    }>
}>;

type Props = {
    id?: string,
    value?: string,
    setValue?: (value: string) => void,
    onChange?: (value: string) => void,
    modeEdit?: 'basic' | 'full',
    addPlugins?: string[],
    addConf?: object,
    addToolbar?: string[],
    disabled?: boolean,
    print?: boolean,
    selectors?: SelectorProps,
}

const MyEditor = (props: Props) => {

    const editor = useRef(null);
    const imageUploadHandle = async (blobInfo, success, failure) => {
        try {
            const { data } = await sendFile(blobInfo.blob());
            if (data) {
                success(data.arquivo || '');
            }
        } catch (error) {
            console.log(error);
            failure('error');
        }
    }

    const getToolbar = (isprint, mode) => {

        if (isprint) {
            return ['print'];
        }
        if (mode === 'full') {
            return ['undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | seletores '];
        }
        return [];
    }

    const getPlugins = (modeEdit: 'basic' | 'full') => {
        const plugins = [];
        if (props.print) {
            plugins.push('print preview', 'autoresize')
        }
        if (modeEdit === 'full') {
            plugins.push('print preview paste searchreplace directionality visualblocks visualchars image link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons');
        }
        return plugins;
    };

    return (<Editor
        ref={editor}
        apiKey={process.env.REACT_APP_TINY_API || 'zn01d6spox6l3xro05s1pvzk7w3nm2dzyhsy6ygd35s7gfqm'}
        value={props.value}
        id={props.id}
        onEditorChange={(content) => {
            props.setValue && props.setValue(content);
        }}
        init={{
            apiKey: `${process.env.REACT_APP_TINY_API || 'zn01d6spox6l3xro05s1pvzk7w3nm2dzyhsy6ygd35s7gfqm'}`,
            language: 'pt_BR',
            language_url: '/langs/pt_BR.js',
            fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
            content_style: "@page { size: A4 portrait; margin: 10mm 20mm;}",
            plugins: [...getPlugins(props.modeEdit), ...props?.addPlugins || []],
            toolbar_sticky: true,
            autoresize: true,
            images_upload_handler: imageUploadHandle,
            toolbar: [...getToolbar(props.print, props.modeEdit), ...props?.addToolbar || []],
            menubar: props.print ? '' : 'file edit view insert format tools table',
            image_caption: true,
            quickbars_selection_toolbar: 'fontselect fontsizeselect formatselect |alignleft aligncenter alignright alignjustify bold italic | quicklink h2 h3 blockquote quickimage quicktable seletores',
            contextmenu: 'link image imagetools table',
            quickbars_insert_toolbar: 'seletores',
            ...props.addConf,
            ...props.addToolbar,
            setup: function (editor) {
                editor.ui.registry.addMenuButton('marginsPrint', {
                    text: 'Margens de impressão',
                    fetch: function (callback) {
                        let items: any[] = [];
                        callback(items);
                    }
                });
                if (props.selectors) {
                    editor.ui.registry.addMenuButton('seletores', {
                        text: '{} Seletores',
                        fetch: function (callback) {
                            let items: any[] = [];

                            props.selectors.map(select => {
                                items.push({
                                    type: 'nestedmenuitem',
                                    text: select?.titulo,
                                    getSubmenuItems: () => {
                                        if (select?.campos?.length > 0) {
                                            return select.campos.map(campo => ({
                                                type: 'menuitem',
                                                text: campo?.titulo,
                                                onAction: function () {
                                                    editor.insertContent("<b>${" + `${campo.id} - ${campo.titulo}` + "}</b>");
                                                }
                                            }));
                                        } else {
                                            return ''
                                        }
                                    }
                                })
                                return items;
                            });
                            callback(items);
                        }
                    });
                }
            },
        }}
        disabled={props.disabled}

    />);

};

export default MyEditor;