import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, Spinner } from 'react-bootstrap';
import { MdAddCircle, MdSettings } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import MyEditor from '../../../../Components/MyEditor';
import SelectWithSearch from '../../../../Components/SelectWithSearch';
import { useStateValue } from '../../../../providers/StateProvider';
import {
  checkTask,
  duplicateFlow,
  getAllServices,
  getFluxo
} from '../../../../services/flow';
import { getOrganDataList } from '../../../../services/organ';
import { actionTypes } from '../../../../store/reducer';
import { CardIcon } from '../../styles';
import {
  CardFooterCustom,
  CardHeader,
  ContainerMain,
  FormGroupCustom,
  FormLabelCustom,
  Title,
  ActionButton,
  ContainerOption,
  BoxLoading,
  ContainerLegend,
  TitleLoading,
  SubTitleLoading
} from './styles';


const CopyFlow = () => {
  const history = useHistory();
  const [titulo, setTitulo] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [organSelected, setOrgaoSelected] = useState<number>(0);
  const [organSlugSelected, setOrgaoSlugSelected] = useState<string>('');
  const [ativo, setAtivo] = useState<boolean>(true);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [orgaoList, setOrgaoList] = useState<any>(null);
  const [orgao, setOrgao] = useState<any>(null);
  const [orgaoAll, setOrgaoAll] = useState<any>();
  const [servicesList, setServicesList] = useState<any>(null);
  const [serviceSelected, setServiceSelected] = useState<any>(null);
  const [service, setService] = useState<any>(null);
  const [, dispatch] = useStateValue();
  const { flow_slug } = useParams<{ flow_slug: string }>();
  const [loadingOrgao, setLoadingOrgao] = useState<boolean>(false);
  const [loadingServico, setLoadingServico] = useState<boolean>(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [initialTask, setInitialTask] = useState<boolean>(false);
  const [countTask, setCountTask] = useState<number>(0);

  const setOrgaoValueFormatted = (values) => {
    if (values) {
      let orgaoOptions = values.map((value) => ({
        label: value.nome,
        value: value.slug,
      }));
      setOrgaoList(orgaoOptions);
    }
  }

  const setServicoValueFormatted = (values) => {
    if (values) {
      let serviceOptions = values.map((value) => ({
        label: value.titulo,
        value: value.slug,
      }));
      setServicesList(serviceOptions);
    }
  }

  const handleGetById = (slug: string, items: any) => {
    if (slug) {
      return items.filter((item) => item.slug === slug);
    }
  }

  useEffect(() => {
    const getOrgans = async () => {
      const tokenSSO: string | null = localStorage.getItem('gov_access_token');
      try {
        setLoadingOrgao(true);
        const { data } = await getOrganDataList(tokenSSO);
        if (data) {
          setOrgaoValueFormatted(data);
          setOrgaoAll(data);
        }

      } catch (error) {
        console.log(error);

      } finally {
        setLoadingOrgao(false);
      }

    };
    getOrgans();
  }, []);

  const handleCheckTask = async (task_id: string, interval: any) => {
    const token: string | null = localStorage.getItem(
      "gov_access_token"
    );

    try {
      let response: any = await checkTask(task_id, token);
      setCountTask((count) => count + 1);

      if (response?.data?.concluded) {
        setLoadingDuplicate(false);
        setInitialTask(false);
        clearInterval(interval);

        history.push(`/informacoes/${response?.data?.identificador}`);

        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success-alt',
            title: 'Sucesso',
            message: 'Fluxo salvo!',
          },
        });
      }

      if (countTask === 100) {
        throw new Error('Algo deu errado.');
      }
    } catch (error) {
      console.log(error);
      setLoadingDuplicate(false);
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Erro',
          message: 'Algo deu errado ao gerar a duplicação!',
        },
      });
    }
  }

  const handleDuplicate = async (e) => {
    const token: string | null = localStorage.getItem(
      "gov_access_token"
    );
    try {
      if (descricao && serviceSelected && organSelected && titulo) {
        setShowMessageError(false);
        if (flow_slug) {
          const responseDuplicate: any = await duplicateFlow(
            flow_slug,
            titulo,
            organSelected,
            serviceSelected || '',
            ativo,
            descricao,
            token
          );

          setTaskId(responseDuplicate.data?.task_id);
          setInitialTask(true);
          setLoadingDuplicate(true);
        }
      } else {
        setShowMessageError(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  let interval = useCallback((task_id: string) => {
    let intervalTask = setInterval(() => handleCheckTask(task_id, intervalTask), 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (taskId && initialTask) {
      interval(taskId);
    }
  }, [interval, initialTask, taskId]);


  const handleCloseCancel = () => {
    history.push('/fluxo');
  }

  const getServices = async () => {
    setLoadingServico(true);
    try {
      const { data } = await getAllServices(organSlugSelected);

      if (data) {
        setServicoValueFormatted(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServico(false);
    }
  }

  useEffect(() => {
    if (organSelected && organSlugSelected) {
      getServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organSelected, organSlugSelected]);

  useEffect(() => {
    const getAllData = async () => {
      try {
        const { data } = await getFluxo(flow_slug);
        if (data) {
          setDescricao(data.descricao);
          setTitulo(data.titulo);
          setServiceSelected(data.servico_slug);
          setOrgaoSelected(data?.orgao || 0);
          setAtivo(data.ativo);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (flow_slug) {
      getAllData();
    }
  }, [flow_slug]);

  const handleSelectOrgao = (value: { label: string, value: string }) => {
    if (value) {
      let [orgao] = handleGetById(value.value, orgaoAll);
      setOrgao(value);
      setOrgaoSelected(orgao.id);
      setOrgaoSlugSelected(orgao.slug);
      setShowMessageError(false);
      onRemoveServiceValues();
    }
  }

  const handleSelectService = (value: { label: string, value: string }) => {
    if (value) {
      setServiceSelected(value.value);
      setService(value);
      setShowMessageError(false);
    }
  }

  const onRemoveServiceValues = () => {
    setServicesList([]);
    setServiceSelected('');
    setService(null);
  }

  return (
    <>
      <ContainerMain>
        {loadingDuplicate && (
          <BoxLoading>
            <Spinner
              animation="border"
              style={{ width: 80, height: 80, fontSize: 34 }}
              variant="primary"
            />
            <ContainerLegend>
              <TitleLoading>
                Preparando seu fluxo
              </TitleLoading>
              <SubTitleLoading>
                Por favor, aguarde um instante...
              </SubTitleLoading>
            </ContainerLegend>
          </BoxLoading>
        )}
        {!loadingDuplicate && (
          <form onSubmit={(e) => e.preventDefault()}>
            <Card>
              <CardHeader>
                <div>
                  <CardIcon>
                    {flow_slug ? (
                      <MdSettings size={20} />
                    ) : (
                      <MdAddCircle size={20} />
                    )}
                  </CardIcon>
                </div>
                <Title>Copiar fluxo</Title>
              </CardHeader>
              <Card.Body>
                <FormGroupCustom className="mb-3" controlId="formBasicEmail">
                  <FormLabelCustom required>Título</FormLabelCustom>
                  <Form.Control
                    type="text"
                    value={titulo}
                    required
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Informe um título para o fluxo
                  </Form.Text>
                </FormGroupCustom>
                <FormGroupCustom>
                  <FormLabelCustom required>
                    Órgão Responsável
                  </FormLabelCustom>
                  <SelectWithSearch
                    name='orgao'
                    options={orgaoList}
                    isDisabled={!orgaoList || loadingOrgao}
                    value={orgao}
                    onChange={handleSelectOrgao}
                    placeholder="Selecione ou digite o nome do órgão"
                  />
                  <Form.Text className="text-muted">
                    Informe o órgão responsável pelo fluxo
                  </Form.Text>
                  {showMessageError && !organSelected && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>

                <FormGroupCustom>
                  <FormLabelCustom required>Descrição</FormLabelCustom>
                  <MyEditor
                    value={descricao}
                    setValue={setDescricao}
                  />
                  <Form.Text className="text-muted">
                    Descreva o que é o fluxo
                  </Form.Text>
                  {showMessageError && !descricao && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>
                <FormGroupCustom>
                  <FormLabelCustom required>
                    Identificador do serviço
                  </FormLabelCustom>
                  <SelectWithSearch
                    name='servicos'
                    value={service}
                    isDisabled={!servicesList?.length || loadingServico}
                    onChange={handleSelectService}
                    options={servicesList}
                    placeholder="Selecione ou digite o nome do serviço"
                  />
                  <Form.Text className="text-muted">
                    Informe o identificador do serviço correspondente ao fluxo
                  </Form.Text>
                  {showMessageError && !serviceSelected && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>
                <FormGroupCustom>
                  <FormLabelCustom required>Está ativo?</FormLabelCustom>
                  <ContainerOption>
                    <Form.Check
                      type="radio"
                      id="radio-active"
                      label="Sim"
                      name="radio-active"
                      value="true"
                      defaultChecked
                      checked={ativo ? true : false}
                      onChange={() => setAtivo(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-active"
                      label="Não"
                      value="false"
                      name="radio-active"
                      checked={ativo ? false : true}
                      onChange={() => setAtivo(false)}
                    />
                  </ContainerOption>
                </FormGroupCustom>
              </Card.Body>
              <CardFooterCustom>
                <ActionButton
                  className="button"
                  style={{ backgroundColor: "#fff", color: "#506176" }}
                  onClick={handleCloseCancel}
                >
                  <span>Cancelar</span>
                </ActionButton>
                <ActionButton
                  style={{ backgroundColor: "#27AB6E" }}
                  type="submit"
                  onClick={handleDuplicate}
                >
                  <span>Salvar</span>
                </ActionButton>
              </CardFooterCustom>
            </Card>
          </form>
        )}
      </ContainerMain>
    </>
  );
};

export default CopyFlow;
