import React, { useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton
} from "../Information/components/ManageForm/styles";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { getProcessing, postProcessing, putProcessing } from "../../services/processing";
import { getOrganDataList } from "../../services/organ";
import { actionTypes } from "../../store/reducer";
import { useStateValue } from "../../providers/StateProvider";
import { getSetorById } from "../../services/setor";
import { getMaxNumberInData } from "../../utils/getMaxNumberInData";
import { ProcessTypeProps } from "../../@types/information";

type StatusProp = 'Encaminhado' | 'Em Andamento' | 'Finalizado';

const statusList = [
  {
    id: 0,
    title: 'Encaminhado',
    value: 'Encaminhado',
  },
  {
    id: 1,
    title: 'Em Andamento',
    value: 'Em Andamento'
  },
  {
    id: 2,
    title: 'Finalizado',
    value: 'Finalizado'
  }
];

const ProcessingForm = () => {
  const history = useHistory();
  const [ordem, setOrdem] = useState<string>("1");
  const [titulo, setTitulo] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [orgaoList, setOrgaoList] = useState<any>(null);
  const [setorList, setSetorList] = useState<any>(null);
  const [loadingOrgao, setLoadingOrgao] = useState<boolean>(false);
  const { flow_slug } = useParams<{ flow_slug: string }>();
  const [organSelected, setOrgaoSelected] = useState<number>(0);
  const [setorSelected, setSetorSelected] = useState<string>('');
  const [statusSelected, setStatusSelected] = useState<StatusProp>();
  const [, dispatch] = useStateValue();
  const [ativo, setAtivo] = useState<boolean>(true);
  const [loadingProcess, setLoadingProcess] = useState(false);



  const location = useLocation();

  const { state }: any = location;
  const process: any = state?.process;


  const handleSave = async (e) => {
    try {
      if (process) {
        await putProcessing(flow_slug, process.id, ordem, titulo, organSelected, ativo, setorSelected, statusSelected, descricao);
      } else {
        await postProcessing(flow_slug, ordem, titulo, organSelected, ativo, setorSelected, statusSelected, descricao);
      }
      history.goBack();
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'success-alt',
          title: 'Sucesso',
          message: 'Tramitação salva!',
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  const getSetorbyIdOrgan = async (orgao_id: number) => {
    const setores = await getSetorById(orgao_id);
    setSetorList(setores.data);
  }

  const getOldProcessing = async (flow: string) => {
    try {
      setLoadingProcess(true);
      const { data } = await getProcessing(flow);
      if (data?.length > 0) {
        const getNumberMaxOrder = getMaxNumberInData<ProcessTypeProps>(data, 'ordem');
        setOrdem((getNumberMaxOrder + 1).toString())
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingProcess(false);
    }
  };

  useEffect(() => {
    const getSetorByIdOrg = async () => {
      await getSetorbyIdOrgan(organSelected)
      if (state?.process) {
        setSetorSelected(process.setor)
      }
    }
    getSetorByIdOrg();

  }, [organSelected, process, state])

  useEffect(() => {
    const process: any = state?.process

    const getOrgans = async () => {
      const tokenSSO: string | null = localStorage.getItem('gov_access_token');
      try {
        setLoadingOrgao(true);
        const { data } = await getOrganDataList(tokenSSO);
        if (data) {
          setOrgaoList(data);
        }
      } catch (error) {

      } finally {
        setLoadingOrgao(false);
      }

    };

    getOrgans();
    if (process) {
      setOrdem(process.ordem);
      setTitulo(process.atividade);
      setOrgaoSelected(process.orgao);
      setAtivo(process.ativo);
      setStatusSelected(process.status);
      setDescricao(process.descricao);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flow_slug && !process) {
      getOldProcessing(flow_slug);
    }
  }, [flow_slug, process]);

  return (
    <ContainerMain>
      <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>Gerenciar Tramitação</Title>
          </CardHeader>

          <Card style={{ border: 0, padding: '40px 40px 0px 40px' }}>
            <CustomRow>
              <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Ordem</FormLabel>
                  <Form.Control value={ordem} disabled={loadingProcess} onChange={(event) => setOrdem(event.target.value)} type="number" defaultValue={1} />
                  <Form.Text className="text-muted">
                    Informe a ordem de exibição.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={10}>
                <Form.Group className="mb-3">
                  <FormLabel>Atividade</FormLabel>
                  <Form.Control required value={titulo} onChange={(event) => setTitulo(event.target.value)} type="text" placeholder="" />
                  <Form.Text className="text-muted">
                    Informe o título para a atividade
                  </Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>
            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Descrição</FormLabel>
                <Form.Control required value={descricao} onChange={(event) => setDescricao(event.target.value)} type="text" placeholder="" />
                <Form.Text className="text-muted">
                  Informe uma breve descrição dessa etapa
                </Form.Text>
              </Form.Group>
            </CustomRow>

            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Status</FormLabel>
                <Form.Select required onChange={(e) => {
                  const findStatus = statusList?.find((oneStatus) => oneStatus.value === e.target.value);
                  setStatusSelected(findStatus.value as StatusProp);
                }}
                >
                  <option value={null}>{'Selecione'}</option>
                  {statusList && statusList.map((oneStatus) => (<option key={oneStatus.id} value={oneStatus.value} selected={oneStatus.value === statusSelected}>{oneStatus.title}</option>))}
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe o status
                </Form.Text>
              </Form.Group>
            </CustomRow>

            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Órgão</FormLabel>
                <Form.Select required onChange={(e) => {
                  const findOrgao = orgaoList?.find((orgao) => orgao.id === parseInt(e.target.value));
                  setOrgaoSelected(findOrgao.id);
                }}
                  disabled={loadingOrgao}
                >
                  <option value={null}>{loadingOrgao ? 'Carregando...' : 'Selecione'}</option>
                  {orgaoList && orgaoList.map((orgao) => (<option key={orgao.slug} value={orgao.id} selected={orgao.id === organSelected}>{orgao.nome}</option>))}
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe o órgão correspondente a tramitação
                </Form.Text>
              </Form.Group>
            </CustomRow>


            {statusSelected && statusSelected !== "Encaminhado" && (<CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Setor</FormLabel>
                <Form.Select required onChange={(e) => {
                  const findSetor = setorList?.find((setor) => setor.id === e.target.value);
                  setSetorSelected(findSetor.id);
                }}
                  disabled={organSelected === 0}
                >
                  <option value={null}>{organSelected === 0 ? 'Carregando...' : 'Selecione'}</option>
                  {setorList && setorList.map((setor) => (<option key={setor.id} value={setor.id} selected={setor.id === setorSelected}>{setor.nome}</option>))}
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe o setor correspondente a tramitação
                </Form.Text>
              </Form.Group>
            </CustomRow>)}

            <CustomRow>
              <div className="mb-3">
                <FormLabel>Está ativo?</FormLabel>
                <Form.Check checked={ativo ? true : false} onChange={() => setAtivo(true)} defaultChecked name="active" value="true" type="radio" label="Sim" id="inline-radio-1" />
                <Form.Check checked={ativo ? false : true} onChange={() => setAtivo(false)} name="active" type="radio" value="false" label="Não" id="inline-radio-1" />
              </div>
            </CustomRow>

          </Card>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.goBack()}
            >
              <span style={{ marginLeft: 8 }}>
                Cancelar
              </span>
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#27AB6E' }}
              type='submit'
              onClick={handleSave}
            >
              <span style={{ marginLeft: 8 }}>
                Salvar
              </span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form>
    </ContainerMain>
  );
};

export default ProcessingForm;
