import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton
} from "../Information/components/ManageForm/styles";
import { useHistory, useParams } from "react-router-dom";
import { actionTypes } from "../../store/reducer";
import { useStateValue } from "../../providers/StateProvider";
import MyEditor from "../../Components/MyEditor";
import { schema } from "./validation";
import { getServiceTermsById, saveServiceTerms } from "../../services/flow";


const NewTerm = () => {
  const history = useHistory();
  const [, dispatch] = useStateValue();
  const [errorsFieldRequired, setErrorsFieldRequired] = useState<any | undefined>();
  const { flow_slug, term_id } = useParams<{ flow_slug: string, term_id?: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [term, setTerm] = useState<any>({
    titulo: "",
    versao: "0.0.0",
    texto: "",
  });




  const handleErrors = (data: any) => {
    let errors = {};
    schema.validate(data, { abortEarly: false }).catch((err) => {
      for (let error of err.inner) {
        const { path, message } = error;
        Object.assign(
          errors, { [path]: message });
      }
      setErrorsFieldRequired(() => errors);
    });

    return errors;
  }
  const handleSave = async (e) => {
    try {
      e.preventDefault();

      handleErrors(term);

      if (!term.titulo ||
        !term.versao ||
        !term.texto
      ) {
        return;
      }
      const tokenSSO: string | null = localStorage.getItem('gov_access_token');

      await saveServiceTerms(tokenSSO, flow_slug, term);

      history.push(`/informacoes/${flow_slug}`);
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'success-alt',
          title: 'Sucesso',
          message: 'Termo salvo com sucesso!',
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Erro',
          message: 'Erro ao salvar termo! Tente novamente',
        },
      });
      console.error(err);
    }
  }

  const getTerms = useCallback(async () => {
    try {
      const tokenSSO: string | null = localStorage.getItem('gov_access_token');
      setLoading(true);
      const { data } = await getServiceTermsById(tokenSSO, flow_slug, term_id);

      setTerm({
        titulo: data?.titulo,
        versao: data?.versao,
        texto: data?.texto,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [flow_slug, term_id]);

  useEffect(() => {
    if (term_id) {
      getTerms();

    }
  }, [term_id, getTerms]);


  return (
    <ContainerMain>
      <form onSubmit={handleSave}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>{term_id ? 'Copiar' : 'Gerenciar'} Termos</Title>
          </CardHeader>

          <Card style={{ border: 0, padding: '40px 40px 0px 40px' }}>
            <CustomRow>
              <Col xs={12} md={10}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Título</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={term.titulo} onChange={(event) => {
                      setTerm({ ...term, titulo: event.target.value })
                    }} />
                  <Form.Text className="text-muted">
                    Informe o título.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={2}>
                <Form.Group className="mb-3">
                  <FormLabel>Versão</FormLabel>
                  <Form.Control disabled={loading} required value={term.versao} onChange={(event) =>
                    setTerm({ ...term, versao: event.target.value })
                  }
                    type="text" placeholder="" defaultValue={"0.0.0"} />
                  <Form.Text className="text-muted">
                    Versão deste termo
                  </Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>
            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Conteúdo</FormLabel>
                <MyEditor
                  disabled={loading}
                  value={term.texto}
                  modeEdit='full'
                  addConf={{
                    height: 500
                  }}
                  setValue={(value) => setTerm({ ...term, texto: value })}
                />
                {errorsFieldRequired && errorsFieldRequired?.texto ?
                  <Form.Text className="text-danger">
                    {errorsFieldRequired?.texto}
                  </Form.Text>
                  :
                  <Form.Text className="text-muted">
                    Adicione o conteúdo
                  </Form.Text>}
              </Form.Group>
            </CustomRow>
          </Card>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.push(`/informacoes/${flow_slug.trim()}`)}
            >
              <span style={{ marginLeft: 8 }}>
                Cancelar
              </span>
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: '#27AB6E' }}
              type='submit'
            >
              <span style={{ marginLeft: 8 }}>
                Salvar
              </span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form>
    </ContainerMain>
  );
};

export default NewTerm;
