import React, { useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  CardBody,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton
} from "./styles";
import { createFormInFlow, getFormInFlowById, updateFormInFlow } from "../../../../services/form";
import { useHistory, useParams } from "react-router-dom";
import MyEditor from "../../../../Components/MyEditor";
import { actionTypes } from "../../../../store/reducer";
import { useStateValue } from "../../../../providers/StateProvider";
import { getFluxo } from "../../../../services/flow";
import { getMaxNumberInData } from "../../../../utils/getMaxNumberInData";
import { getFormsProps } from "../../../../@types/information";

const ManageForm = () => {
  const history = useHistory();
  const [descricao, setDescricao] = useState<string>("");
  const [ordem, setOrdem] = useState<string>("1");
  const [titulo, setTitulo] = useState<string>("");
  const [orgao] = useState<number>(1);
  const [ativo, setAtivo] = useState<boolean>(true);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [, dispatch] = useStateValue();

  const { flow_slug, form_slug } = useParams<{ flow_slug: string, form_slug: string }>();

  const handleSave = async (e) => {
    try {
      if (descricao) {
        setShowMessageError(false);
        if (form_slug && descricao) {
          await updateFormInFlow(flow_slug, form_slug, titulo, descricao, ativo, orgao, ordem);
        } else {
          await createFormInFlow(ordem, titulo, descricao, flow_slug, orgao, ativo);
        }
        history.goBack();

        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success-alt',
            title: 'Sucesso',
            message: 'Formulário criado!',
          },
        });

      } else {
        setShowMessageError(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const formById = async () => {
    try {
      const { data } = await getFormInFlowById(flow_slug, form_slug);
      if (data) {
        setDescricao(data.descricao);
        setOrdem(data.ordem.toString());
        setTitulo(data.titulo);
        setAtivo(data.ativo)
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  const getOldForms = async (flow: string) => {
    try {
      setLoadingOrders(true);
      const { data } = await getFluxo(flow);
      if (data?.formularios?.length > 0) {
        const getNumberMaxOrder = getMaxNumberInData<getFormsProps>(data.formularios, 'ordem');
        setOrdem((getNumberMaxOrder + 1).toString());
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrders(false);
    }
  }

  useEffect(() => {
    if (form_slug) {
      formById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (flow_slug && !form_slug) {
      getOldForms(flow_slug);
    }
  }, [flow_slug, form_slug]);

  return (
    <ContainerMain>
      <form onSubmit={(e) => e.preventDefault()}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>{form_slug ? "Editar " : "Gerenciar "}Formulário</Title>
          </CardHeader>

          <CardBody>
            <CustomRow>
              <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Ordem</FormLabel>
                  <Form.Control value={ordem} disabled={loadingOrders} onChange={(event) => setOrdem(event.target.value)} type="number" defaultValue={1} />
                  <Form.Text className="text-muted">
                    Informe a ordem de exibição.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col xs={12} md={10}>
                <Form.Group className="mb-3">
                  <FormLabel>Título</FormLabel>
                  <Form.Control required value={titulo} onChange={(event) => setTitulo(event.target.value)} type="text" placeholder="" />
                  <Form.Text className="text-muted">
                    Informe um título para o formulário
                  </Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Descrição</FormLabel>
                <MyEditor
                  value={descricao}
                  setValue={setDescricao}
                  addConf={{
                    height: 300
                  }}
                />
                <Form.Text className="text-muted">
                  Descreva o que é o formulário
                </Form.Text>
                {showMessageError && !descricao && (
                  <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>
                    Este campo não pode ser vazio.
                  </p>
                )}
              </Form.Group>
            </CustomRow>



            <CustomRow>
              <div className="mb-3">
                <FormLabel>Está ativo?</FormLabel>
                <Form.Check checked={ativo ? true : false} onChange={() => setAtivo(true)} defaultChecked name="active" value="true" type="radio" label="Sim" id="inline-radio-1" />
                <Form.Check checked={ativo ? false : true} onChange={() => setAtivo(false)} name="active" type="radio" value="false" label="Não" id="inline-radio-1" />
              </div>
            </CustomRow>

          </CardBody>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.push(`/informacoes/${flow_slug}`)}
            >
              <span style={{ marginLeft: 8 }}>
                Cancelar
              </span>
            </ActionButton>
            <ActionButton
              style={{ backgroundColor: "#27AB6E" }}
              type='submit'
              onClick={handleSave}
            >
              <span style={{ marginLeft: 8 }}>
                Salvar
              </span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form>
    </ContainerMain>
  );
};

export default ManageForm;
