import { MdBlock } from "react-icons/md";
import {
  CustomFooter,
  BoxWarning,
  CardIcon,
  CustomModal,
  TitleWarning,
  Warning,
  CustomBody,
  ButtonDeny,
  ButtonConfirmed,
} from "./style";
import { useCallback, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useStateValue } from "../../../../providers/StateProvider";
import { actionTypes } from "../../../../store/reducer";
import { deleteWorkflowByFlowId } from "../../../../services/flow";
interface Props {
  show: boolean;
  handleShow: (del?: boolean) => void;
  flow_slug: string;
  workflow: any;
}

const DeleteModal = ({ show, handleShow, workflow, flow_slug }: Props) => {
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true);
      await deleteWorkflowByFlowId({
        flow_slug,
        id: workflow.id,
      });
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'success-alt',
          title: 'Sucesso',
          message: 'Workflow excluído com sucesso!',
        },
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);

    } catch (error) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: 'error-alt',
          title: 'Erro',
          message: 'Você não pode realizar essa ação!',
        },
      });
      setLoading(false);
    }

  }, [flow_slug, workflow.id, dispatch]);

  return (
    <CustomModal show={show} centered>
      <CustomBody>
        <BoxWarning>
          <CardIcon>
            <MdBlock size={20} />
          </CardIcon>
          <TitleWarning>{loading ? 'Excluindo' : 'Excluir'} a  workflow {workflow.titulo}</TitleWarning>
          <Warning>
            <span>Tem certeza de que deseja excluir esta workflow?</span>{" "}
            <span>Essa ação não pode ser desfeita.</span>
          </Warning>
        </BoxWarning>
      </CustomBody>

      <CustomFooter>
        {loading ?
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}>
            <CircularProgress size={20} />
          </div> : <>
            <ButtonDeny onClick={() => handleShow(false)} >Não</ButtonDeny>
            <ButtonConfirmed onClick={handleDelete}>Sim</ButtonConfirmed>
          </>}
      </CustomFooter>
    </CustomModal >
  );
};

export default DeleteModal;
