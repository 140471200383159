import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { MdAddCircle, MdSettings } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../../../../Components/Loading';
import MyEditor from '../../../../Components/MyEditor';
import SelectWithSearch from '../../../../Components/SelectWithSearch';
import { useStateValue } from '../../../../providers/StateProvider';
import { createFluxo, getAllServices, getFluxo, updateFluxo } from '../../../../services/flow';
import { getOrganDataList } from '../../../../services/organ';
import { actionTypes } from '../../../../store/reducer';
import { CardIcon } from '../../styles';
import {
  CardFooterCustom,
  CardHeader,
  ContainerMain,
  FormGroupCustom,
  FormLabelCustom,
  Title,
  ActionButton,
  ContainerOption
} from './styles';


const ManageFlow = () => {
  const history = useHistory();
  const [titulo, setTitulo] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [organSelected, setOrgaoSelected] = useState<number>(0);
  const [organSlugSelected, setOrgaoSlugSelected] = useState<string>('');
  const [ativo, setAtivo] = useState<boolean>(true);
  const [automatico, setAutomatico] = useState<boolean>(false);
  const [terceiros, setTerceiros] = useState<boolean>(false);
  const [tipoFluxo, setTipoFluxo] = useState<"Manual" | "Automático" | "Semi-Automático">("Manual");
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [orgaoList, setOrgaoList] = useState<any>(null);
  const [orgao, setOrgao] = useState<any>(null);
  const [orgaoAll, setOrgaoAll] = useState<any>();
  const [servicesList, setServicesList] = useState<any>(null);
  const [serviceSelected, setServiceSelected] = useState<any>(null);
  const [service, setService] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [, dispatch] = useStateValue();
  const { flow_slug } = useParams<{ flow_slug: string }>();
  const [loadingOrgao, setLoadingOrgao] = useState<boolean>(false);
  const [loadingServico, setLoadingServico] = useState<boolean>(false);
  const [hasModelFlow, setHasModelFlow] = useState<boolean>(false);

  const setOrgaoValueFormatted = (values) => {
    if (values) {
      let orgaoOptions = values.map((value) => ({
        label: `${value.nome} - ${value?.sigla}`,
        value: value.slug,
      }));
      setOrgaoList(orgaoOptions);
    }
  }

  const setServicoValueFormatted = (values) => {
    if (values) {
      let serviceOptions = values.map((value) => ({
        label: value.titulo,
        value: value.slug,
      }));
      setServicesList(serviceOptions);
      if (serviceSelected) {
        const service = handleGetById(serviceSelected, serviceOptions);
        if (service?.length) {
          setService({ label: service[0].label, value: service[0].value });
        }
      }
    }
  }

  const handleGetById = (slug: string, items: any) => {
    if (slug) {
      return items.filter((item) => item.slug === slug);
    }
  }


  const getOrgans = async () => {
    const tokenSSO: string | null = localStorage.getItem('gov_access_token');
    try {
      setLoadingOrgao(true);
      const { data } = await getOrganDataList(tokenSSO);
      if (data) {
        setOrgaoValueFormatted(data);
        setOrgaoAll(data);
        return data;
      }

    } catch (error) {

    } finally {
      setLoadingOrgao(false);
    }

  };

  const handleSave = async (e) => {
    e.preventDefault()

    try {
      if ((!hasModelFlow && descricao && serviceSelected && organSelected) || (hasModelFlow && titulo && descricao)) {
        setShowMessageError(false);
        if (flow_slug) {
          await updateFluxo(flow_slug, titulo, descricao, serviceSelected || '', ativo, organSelected, hasModelFlow, automatico, terceiros, tipoFluxo);
        } else {
          await createFluxo({
            titulo: titulo,
            descricao: descricao,
            servico_slug: serviceSelected || '',
            ativo: ativo,
            orgao: organSelected,
            modelo: hasModelFlow,
            automatico: automatico,
            terceiros: terceiros,
            tipo: tipoFluxo,
          });
        }
        history.push('/fluxo');
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'success-alt',
            title: 'Sucesso',
            message: 'Fluxo salvo!',
          },
        });
      } else {
        setShowMessageError(true);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'error-alt',
            title: 'Error ao salvar',
            message: err?.response?.data?.message,
          },
        });
      }
      console.error(err?.response?.data?.message);
    }
  };

  const handleCloseCancel = () => {
    history.push('/fluxo');
  }

  const getServices = async (orgaoSlug?: string) => {
    setLoadingServico(true);
    try {
      const { data } = await getAllServices(orgaoSlug);
      if (data) {
        setServicoValueFormatted(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServico(false);
    }
  }

  useEffect(() => {
    if (organSelected && organSlugSelected) {
      getServices(organSlugSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organSelected, organSlugSelected]);

  useEffect(() => {
    const getAllData = async () => {
      try {
        setLoading(true);
        const listOrgans: any = await getOrgans();
        const { data } = await getFluxo(flow_slug);
        if (data) {
          if (listOrgans?.length) {
            const orgaoSlug = listOrgans.filter((item) => item.id === data.orgao);
            if (orgaoSlug?.length) {
              setOrgaoSelected(orgaoSlug[0].id);
              setOrgaoSlugSelected(orgaoSlug[0].slug);
              setOrgao({ label: `${orgaoSlug[0].nome} - ${orgaoSlug[0].sigla}`, value: orgaoSlug[0].slug });
              const listService = await getServices(orgaoSlug[0].slug);
              if (listService?.length) {
                const serviceSlug = listService.filter((item) => item.slug === data.servico_slug);
                if (serviceSlug?.length) {
                  setService({ label: serviceSlug[0].titulo, value: serviceSlug[0].slug });
                }
              }
            }
          }
          setDescricao(data.descricao);
          setTitulo(data.titulo);
          setServiceSelected(data.servico_slug);
          setAtivo(data.ativo);
          setHasModelFlow(data.modelo);
          setAutomatico(data.automatico);
          setTerceiros(data.terceiros);
          setTipoFluxo(data.tipo);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (flow_slug) {
      getAllData();
    } else {
      getOrgans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow_slug]);

  const handleSelectOrgao = (value: { label: string, value: string }) => {
    if (value) {
      let [orgao] = handleGetById(value.value, orgaoAll);
      setOrgao(value);
      setOrgaoSelected(orgao.id);
      setOrgaoSlugSelected(orgao.slug);
      setShowMessageError(false);
      onRemoveServiceValues();
    }
  }

  const handleSelectService = (value: { label: string, value: string }) => {
    if (value) {
      setServiceSelected(value.value);
      setService(value);
      setShowMessageError(false);
    }
  }

  const onRemoveServiceValues = () => {
    setServicesList([]);
    setServiceSelected('');
    setService(null);
  }

  useEffect(() => {
    if (hasModelFlow) {
      setServiceSelected(null);
    }
  }, [hasModelFlow]);

  return (
    <>
      {loading ? (
        <div className="d-flex mt-4 justify-content-center">
          <Loading />
        </div>
      ) : (
        <ContainerMain>
          <form onSubmit={handleSave}>
            <Card>
              <CardHeader>
                <div>
                  <CardIcon>
                    {flow_slug ? (
                      <MdSettings size={20} />
                    ) : (
                      <MdAddCircle size={20} />
                    )}
                  </CardIcon>
                </div>
                <Title>{`${flow_slug ? "Gerenciar" : "Novo"} Fluxo`}</Title>
              </CardHeader>
              <Card.Body>
                <FormGroupCustom className="mb-3">
                  <FormLabelCustom required>
                    Deseja tornar este fluxo como modelo?
                  </FormLabelCustom>
                  <ContainerOption>
                    <Form.Check
                      type="radio"
                      id="radio-hasmodelflow"
                      label="Sim"
                      name="radio-modelflow"
                      value="true"
                      defaultChecked
                      checked={hasModelFlow ? true : false}
                      onChange={() => setHasModelFlow(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-hasmodelflow"
                      label="Não"
                      value="false"
                      name="radio-modelflow"
                      checked={hasModelFlow ? false : true}
                      onChange={() => setHasModelFlow(false)}
                    />
                  </ContainerOption>
                </FormGroupCustom>
                <FormGroupCustom className="mb-3" controlId="formBasicEmail">
                  <FormLabelCustom required>Título</FormLabelCustom>
                  <Form.Control
                    type="text"
                    value={titulo}
                    required
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Informe um título para o fluxo
                  </Form.Text>
                </FormGroupCustom>

                <FormGroupCustom>
                  <FormLabelCustom required>
                    Órgão Responsável
                  </FormLabelCustom>
                  <SelectWithSearch
                    name='orgao'
                    options={orgaoList}
                    isDisabled={!orgaoList || loadingOrgao}
                    value={orgao}
                    isLoading={loadingOrgao}
                    onChange={handleSelectOrgao}
                    placeholder="Selecione ou digite o nome do órgão"
                  />
                  <Form.Text className="text-muted">
                    Informe o órgão responsável pelo fluxo
                  </Form.Text>
                  {showMessageError && !organSelected && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>

                <FormGroupCustom>
                  <FormLabelCustom required>Descrição</FormLabelCustom>
                  <MyEditor
                    value={descricao}
                    setValue={setDescricao}
                  />
                  <Form.Text className="text-muted">
                    Descreva o que é o fluxo
                  </Form.Text>
                  {showMessageError && !descricao && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Identificador do serviço
                    </FormLabelCustom>
                    <SelectWithSearch
                      name='servicos'
                      value={service}
                      isDisabled={!servicesList?.length || loadingServico}
                      onChange={handleSelectService}
                      isLoading={loadingServico}
                      options={servicesList}
                      placeholder="Selecione ou digite o nome do serviço"
                    />
                    <Form.Text className="text-muted">
                      {!servicesList?.length ? "Nenhum serviço listado" : 'Informe o identificador do serviço correspondente ao fluxo'}
                    </Form.Text>
                    {showMessageError && !serviceSelected && (
                      <p
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Este campo não pode ser vazio.
                      </p>
                    )}
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Está ativo?</FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-active"
                      label="Sim"
                      name="radio-active"
                      value="true"
                      defaultChecked
                      checked={ativo ? true : false}
                      onChange={() => setAtivo(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-active"
                      label="Não"
                      value="false"
                      name="radio-active"
                      checked={ativo ? false : true}
                      onChange={() => setAtivo(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Resposta automática</FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-active-auto"
                      label="Sim"
                      name="radio-active-auto"
                      value="true"
                      defaultChecked
                      checked={automatico ? true : false}
                      onChange={() => setAutomatico(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-active-auto"
                      label="Não"
                      value="false"
                      name="radio-active-auto"
                      checked={automatico ? false : true}
                      onChange={() => setAutomatico(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>O serviço pode ser solicitado para terceiros?</FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-terceiros-auto"
                      label="Sim"
                      name="radio-terceiros-auto"
                      value="true"
                      defaultChecked
                      checked={terceiros ? true : false}
                      onChange={() => setTerceiros(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-terceiros-auto"
                      label="Não"
                      value="false"
                      name="radio-terceiros-auto"
                      checked={terceiros ? false : true}
                      onChange={() => setTerceiros(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Qual o tipo de Fluxo</FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-manual"
                      label="Manual"
                      name="radio-type-flow"
                      value="Manual"
                      defaultChecked
                      checked={tipoFluxo === 'Manual'}
                      onChange={() => setTipoFluxo('Manual')}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-semi"
                      label="Semi-Automático"
                      name="radio-type-flow"
                      value="Semi-Automático"
                      checked={tipoFluxo === 'Semi-Automático'}
                      onChange={() => setTipoFluxo('Semi-Automático')}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-auto"
                      label="Automático"
                      name="radio-type-flow"
                      value="Automático"
                      checked={tipoFluxo === 'Automático'}
                      onChange={() => setTipoFluxo('Automático')}
                    />
                  </FormGroupCustom>
                )}
              </Card.Body>
              <CardFooterCustom>
                <ActionButton
                  className="button"
                  style={{ backgroundColor: "#fff", color: "#506176" }}
                  onClick={handleCloseCancel}
                >
                  <span>Cancelar</span>
                </ActionButton>
                <ActionButton
                  style={{ backgroundColor: "#27AB6E" }}
                  type="submit"
                >
                  <span>Salvar</span>
                </ActionButton>
              </CardFooterCustom>
            </Card>
          </form>
        </ContainerMain>)}
    </>
  );
};

export default ManageFlow;
