import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../providers/StateProvider';
import { logout, getUserData as getUserDataRequest } from '../../services/auth';
import { actionTypes } from '../../store/reducer';

export default function LoginCallback(): JSX.Element {
  const history = useHistory();
  const [{ user }, dispatch] = useStateValue();

  const getUserData = async (tokenSso: string) => {
    try {
      const data = await getUserDataRequest(tokenSso);
      dispatch({
        type: actionTypes.SET_USER,
        user: data,
      });
      localStorage.setItem("gov_user_data", JSON.stringify(data));
    } catch (err) {
      console.log(err);
      logout();
    }
  };

  useEffect(() => {
    const token: string | null = localStorage.getItem("gov_access_token");

    if (token) {
      getUserData(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if ((user)) {
      const pathname = localStorage.getItem("pathname_redirect_gov");
      const spathname = pathname === "/" ? "/home" : pathname;
      history.push(spathname || "/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box
      height="100hv"
      width="100hv"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="secondary" />
    </Box>
  );
}
