import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import dateFormatToBR from "../../utils/dateFormat";
import {
  MdVisibility,
  MdKey,
  MdDelete,
  MdAddCircle,
} from "react-icons/md";
import { CustomTable } from "../Requests/styles";

import {
  Title,
  ContainerMain,
  CardHeader,
  CardFooterCustom,
  Box,
  CardIcon,

  NoFoundData,
  CustomButton,
} from "./style";

import Paginate from "../../Components/Paginate";

import Loading from "../../Components/Loading";
import { useHistory } from "react-router-dom";
import { getCredentialsList } from "../../services/credenciais";
import DeleteModal from "./Components/DeleteModal";

const Credentials = () => {
  const history = useHistory();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentialData, setCredentialData] = useState<any>();
  const [credentialSelected, setCredentialSelected] = useState<any>();


  const getCredentialList = async ({ page }: { page?: number }) => {
    try {
      setLoading(true);
      const { data } = await getCredentialsList(page);
      setCredentialData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getCredentialList({ page: 1 });
    // eslint-disable-next-line
  }, []);
  return (
    <ContainerMain>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdKey size={20} />
                </CardIcon>
                <Title>Credenciais</Title>
              </Box>

              <Button onClick={() => history.push(`/credenciais/gerenciar`)}>
                <MdAddCircle size={20} /> Nova Credencial
              </Button>
            </CardHeader>
            <Card.Body>
              {loading ? (
                <div className="d-flex mt-4 justify-content-center">
                  <Loading />
                </div>
              ) : (
                <>
                  {credentialData && credentialData?.results.length > 0 ? (
                    <CustomTable responsive="md">
                      <thead className="table-header">
                        <tr>
                          <th>Título</th>
                          <th>Adicionado em</th>
                          <th>Última atualização</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {credentialData?.results.map((credential) => (
                          <tr key={credential.id}>
                            <td>{credential.titulo}</td>
                            <td>{dateFormatToBR(credential.created_at, true)}</td>
                            <td>{dateFormatToBR(credential.updated_at, true)}</td>
                            <td>
                              <CustomButton
                                variant="outline-secondary"
                                onClick={() => {
                                  history.push(`/credenciais/${credential.id}/detalhes`);
                                }}
                              >
                                <MdVisibility size={20} className="icon" />
                              </CustomButton>

                              <Button
                                variant="danger"
                                onClick={() => {
                                  setCredentialSelected(credential);
                                  setShowModalDelete(true);
                                }}
                              >
                                <MdDelete size={20} className="icon" />
                              </Button>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </CustomTable>
                  ) : (
                    <NoFoundData>Sem resultados!</NoFoundData>
                  )}
                </>
              )}
            </Card.Body>
            <CardFooterCustom>
              {credentialData?.total_pages > 1 ? (
                <Paginate
                  flowData={credentialData}
                  setPage={getCredentialList}
                  page={credentialData?.current}
                />
              ) : undefined}
            </CardFooterCustom>
          </Card>
        </Col>
      </Row>
      {credentialSelected && <DeleteModal show={showModalDelete} handleShow={setShowModalDelete} credencial={credentialSelected} />}
    </ContainerMain >
  );
};

export default Credentials;
