import { AxiosResponse } from 'axios';
import { apinewforms } from './api';

export const getCredentialsList = (page?: number): Promise<AxiosResponse> =>
  apinewforms.get(`/core/credenciais/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
    params: {
      page,
    }
  });

export const getCredential = (id: string): Promise<AxiosResponse> =>
  apinewforms.get(`/core/credenciais/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });

export const createCredentialsList = (body: {
  token: string,
  titulo: string,
}): Promise<AxiosResponse> =>
  apinewforms.post(`/core/credenciais/`, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });


export const deleteCredential = (id: string): Promise<AxiosResponse> =>
  apinewforms.delete(`/core/credenciais/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('gov_access_token')}`,
    },
  });